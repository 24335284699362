import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { BiDownArrow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import Swal from 'sweetalert2'
import { palette } from "../../Global/GlobeData";
import { IoIosWarning, } from "react-icons/io";
import { FaEnvelope, FaTrashAlt } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { fetchUserImage, onUploadDesignBackground, addDesignData, onDeleteUploadImage } from "../../Actions/BusinessAction";
import productImg from "../../images/product.png"
import { appName } from "../../Global/Global";
const BusinessDesign = ({ mainLoader }) => {

  const [open, setOpen] = useState(false);
  const state = useSelector((state) => state.business.data);
  const design = useSelector((state) => state.business.data.design);
  const dispatch = useDispatch()
  const [enable, setEnable] = useState({
    backgroundEnable: false,
    colorEnable: false,
  });
  const [userImage, setUserImage] = useState([]);
  const [loader, setLoader] = useState({
    background: false,
    cover: false,
  })
  const [hover, setHover] = useState({
    call: false,
    email: false
  });

  const imageFetchData = () => {
    dispatch(fetchUserImage(setUserImage))
  }

  useEffect(() => {
    imageFetchData()
  }, []);

  const [picker, setPicker] = useState({
    bgPicker: false,
    coverPicker: false,
    primaryPicker: false,
    secondPicker: false,
    fontPicker: false,
  });

  const [warning, setWarning] = useState({
    msg: "",
    type: ""
  });

  const handleWarning = () => {
    setTimeout(() => {
      setWarning({ type: "", msg: "" });
    }, 5000);
  };

  const handleBg = (color, type, colorFor, colorType) => {
    let rgba;
    if (type === "picker") {
      rgba = `rgba(${color.r}, ${color.g},${color.b}, ${color.a})`;
    }
    else {
      rgba = color;
    }

    if (colorFor === "bg") {
      dispatch(addDesignData({
        ...design,
        background: rgba,
      }))
    } else if (colorFor === "bgCover") {
      dispatch(addDesignData({
        ...design,
        cover: rgba,
      }))
    }
    else {
      if (colorType === "primary") {
        dispatch(addDesignData({
          ...design,
          primaryColor: rgba,
        }))
      }
      if (colorType === "secondary") {
        dispatch(addDesignData({
          ...design,
          secondColor: rgba,
        }))
      }
      if (colorType === "font") {
        dispatch(addDesignData({
          ...design,
          fontColor: rgba,
        }))
      }
    }
  };

  const handleDelete = (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        dispatch(onDeleteUploadImage(id, imageFetchData))
      }
    });
  }

  const onEnable = (e) => {
    const { name, checked } = e.target;
    if (name === "logoEnable") {
      if (state.logo === "" && !design.logoEnable) {
        setWarning({
          type: "logo",
          msg: "Please add logo data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, logoEnable: checked }))
      }
    } else if (name === "callEnable") {
      if (state.phone === "" && !design.callEnable) {
        setWarning({
          type: "call",
          msg: "Please add phone data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, callEnable: checked }))
      }

    } else if (name === "emailEnable") {
      if (state.email === "" && !design.emailEnable) {
        setWarning({
          type: "email",
          msg: "Please add email data from General Tab to enable it"
        })
      } else {
        dispatch(addDesignData({ ...design, emailEnable: checked }))
      }
    } else if (name === "nameEnable") {
      if (state.name === "" && !design.nameEnable) {
        setWarning({
          type: "name",
          msg: "Please add name data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, nameEnable: checked }))
      }
    } else if (name === "aboutEnable") {
      if (state.address === "" && !design.aboutEnable) {
        setWarning({
          type: "about",
          msg: "Please add address data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, aboutEnable: checked }))
      }

    } else if (name === "descriptionEnable") {
      if (state.description === "" && !design.descriptionEnable) {
        setWarning({
          type: "description",
          msg: "Please add description data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, descriptionEnable: checked }))
      }
    } else {
      if (!state.socialMedia.isSocialMedia && !design.socialMediaEnable) {
        setWarning({
          type: "socialMedia",
          msg: "Please add social media data from General Tab to enable it"
        })
        handleWarning()
      } else {
        dispatch(addDesignData({ ...design, socialMediaEnable: checked }))
      }
    }
  };

  const uploadImage = (e) => {
    let imageData = e.target.files[0]
    let name = e.target.name
    console.log(name, "check name")
    let allowedType = ["image/png", "image/jpg", "image/jpeg"]
    if (imageData) {
      if (allowedType.includes(imageData.type)) {
        if (imageData.size < 5000001) {
          const formData = new FormData()
          formData.append("upload_type", 'logo')
          formData.append("file", imageData)
          setLoader({ ...loader, [name]: true })
          dispatch(onUploadDesignBackground(name, formData, loader, setLoader, imageFetchData))
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size for image is 5MB.',
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select a valid file type.',
        })
      }
    }
  };

  const handleImageClick = (name, image) => {
    dispatch(addDesignData({ ...design, [name]: image }))
  };


  return (
    <>
      <div className="designElem">
        <div className="designElem-left">
          <div className="secCollapse">
            <div
              className="secCollapse-title"
              onClick={() =>
                setEnable({
                  ...enable,
                  backgroundEnable: !enable.backgroundEnable,
                })
              }
            >
              Background
              <span>
                <BiDownArrow />
              </span>
            </div>
            <Collapse in={enable.backgroundEnable}>
              <div className="colorBox">
                {picker.bgPicker ? (
                  <>
                    <div
                      className="wrapper-picker"
                      onClick={() =>
                        setPicker({
                          ...picker,
                          bgPicker: false,
                        })
                      }
                    />
                    <SketchPicker
                      color={design.background}
                      onChange={(e) => handleBg(e.rgb, "picker", "bg")}
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="color_picker_head d-flex align-items-center justify-content-between mb-3">
                  <div className="color_picker_head">
                    <span className="color_picker_heading">Background Color</span>
                  </div>
                  <div className="color_picker_box">
                    <div
                      className="color-single cursor-pointer"
                      style={{
                        background: design.background,
                        border: "1px solid black",
                        width: "42px",
                      }}
                      onClick={() =>
                        setPicker({
                          ...picker,
                          bgPicker: true,
                        })
                      }
                    />
                  </div>
                </div>

                <ul>
                  <li onClick={() => handleBg("", false, "bg")}>
                    <div
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid black",
                      }}
                      className="color-single cursor-pointer"
                    ></div>
                  </li>
                  {palette.map((curElem, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleBg(curElem, false, "bg")}
                      >
                        <div
                          style={{ background: curElem }}
                          className="color-single cursor-pointer"
                        ></div>
                      </li>
                    );
                  })}
                </ul>

                <div>
                  <div className="upBg mt-3">
                    <div>
                      {loader.background ?
                        <i
                          className="fa fa-spinner fa-spin mx-1"
                          style={{ fontSize: "20px" }}
                        />
                        : <h6>Upload Background Image</h6>
                      }
                      <input type="file" name="background" onChange={(e) => uploadImage(e)} />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <ul className="bgImgList pt-2 justify-content-xl-center "  >
                    {userImage.length > 0 ?
                      userImage.map((curElem, index) => {
                        return (
                          <>
                            {
                              curElem.image === design.background ?
                                <>
                                  {curElem.image === "" ?
                                    <i
                                      className="fa fa-spinner fa-spin mx-1"
                                      style={{ fontSize: "20px" }}
                                    />
                                    :
                                    <li key={index} class="bgImg-single" style={{ height: "70px", width: "70px", marginRight: "11px", padding: "0" }} >
                                      <div className="bgImg-single" style={{ height: "100%", textAlign: "center" }}>
                                        <FaTrashAlt
                                          style={{ color: "rgb(210 47 59)" }}
                                          className='delete-icon-media'
                                          onClick={(e) => handleDelete(e, curElem.id)}
                                        />
                                        <img src={curElem.image} style={{ height: "100%" }} alt="image" onClick={() => handleImageClick("background", curElem.image)} />
                                      </div>
                                    </li>
                                  }
                                </>
                                :
                                <>
                                  {curElem.image === "" ?
                                    <i
                                      className="fa fa-spinner fa-spin mx-1"
                                      style={{ fontSize: "20px" }}
                                    />
                                    :
                                    <li key={index} style={{ border: "1px solid #000", height: "70px", width: "70px", marginRight: "8px", borderRadius: "5px", padding: "0" }}>
                                      <div className="bgImg-single" style={{ border: "none", height: "100%", textAlign: "center" }}>
                                        <FaTrashAlt
                                          style={{ color: "rgb(210 47 59)" }}
                                          className='delete-icon-media'
                                          onClick={(e) => handleDelete(e, curElem.id)}
                                        />
                                        <img src={curElem.image} style={{ height: "100%", textAlign: "center", }} alt="image" onClick={() => handleImageClick("background", curElem.image)} />
                                      </div>
                                    </li>
                                  }
                                </>
                            }
                          </>
                        )

                      })
                      : ""
                    }
                  </ul>
                </div>

              </div>
            </Collapse>
          </div>

          <div className='secCollapse' style={{ marginTop: "10px" }}>
            <div className='secCollapse-title' onClick={() => setOpen(!open)}>Cover <span><BiDownArrow /></span> </div>
            <Collapse in={open}>
              <div className='colorBox'>

                {picker.coverPicker ? (
                  <>
                    <div
                      className="wrapper-picker"
                      onClick={() =>
                        setPicker({
                          ...picker,
                          coverPicker: false,
                        })
                      }
                    />
                    <SketchPicker
                      color={design.cover}
                      onChange={(e) => handleBg(e.rgb, "picker", "bgCover")}
                    />
                  </>
                ) : (
                  ""
                )}


                <div className="color_picker_head d-flex align-items-center justify-content-between mb-3">
                  <div className="color_picker_head">
                    <span className="color_picker_heading">Cover Color</span>
                  </div>
                  <div className="color_picker_box">
                    <div
                      className="color-single cursor-pointer"
                      style={{
                        background: design.cover,
                        border: "1px solid black",
                        width: "42px",
                      }}
                      onClick={() =>
                        setPicker({
                          ...picker,
                          coverPicker: true,
                        })
                      }
                    />
                  </div>
                </div>

                <ul>
                  <li onClick={() => handleBg("", false, "bgCover")}>
                    <div
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid black",
                      }}
                      className="color-single cursor-pointer"
                    ></div>
                  </li>
                  {palette.map((curElem, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleBg(curElem, false, "bgCover")}
                      >
                        <div
                          style={{ background: curElem }}
                          className="color-single cursor-pointer"
                        ></div>
                      </li>
                    );
                  })}
                </ul>

                <div>
                  <div className="upBg mt-3">
                    <div>
                      {loader.cover ?
                        <i
                          className="fa fa-spinner fa-spin mx-1"
                          style={{ fontSize: "20px" }}
                        />
                        : <h6>Upload Cover Image</h6>
                      }
                      <input type="file" name="cover" onChange={(e) => uploadImage(e)} />
                    </div>
                  </div>
                </div>

                <ul className="bgImgList pt-2">
                  {userImage.length > 0 ?
                    userImage.map((curElem, index) => {
                      return (
                        <>
                          {
                            curElem.image === design.cover ?
                              <li key={index}>
                                <div className="bgImg-single">
                                  <FaTrashAlt
                                    style={{ color: "rgb(210 47 59)" }}
                                    className='delete-icon-media'
                                    onClick={(e) => handleDelete(e, curElem.id)}
                                  />
                                  <img src={curElem.image} style={{ height: "100%", width: "100%" }} alt="image" onClick={() => handleImageClick("cover", curElem.image)} />
                                </div>
                              </li>
                              : <li key={index}>
                                <div className="bgImg-single" style={{ border: "none" }}>
                                  <FaTrashAlt
                                    style={{ color: "rgb(210 47 59)" }}
                                    className='delete-icon-media'
                                    onClick={(e) => handleDelete(e, curElem.id)}
                                  />
                                  <img src={curElem.image} style={{ height: "100%", width: "100%" }} alt="image" onClick={() => handleImageClick("cover", curElem.image)} />
                                </div>
                              </li>
                          }
                        </>
                      )
                    })
                    : ""
                  }
                </ul>
              </div>
            </Collapse>
          </div>

          <div className="secCollapse" style={{ marginTop: "10px" }}>
            <div
              className="secCollapse-title"
              onClick={() =>
                setEnable({
                  ...enable,
                  colorEnable: !enable.colorEnable,
                })
              }
            >
              Colors{" "}
              <span>
                <BiDownArrow />
              </span>
            </div>
            <Collapse in={enable.colorEnable}>
              <div className="colorBox">
                {picker.primaryPicker ? (
                  <>
                    <div
                      className="wrapper-picker"
                      onClick={() =>
                        setPicker({
                          ...picker,
                          primaryPicker: false,
                        })
                      }
                    />
                    <SketchPicker
                      color={design.primaryColor}
                      onChange={(e) =>
                        handleBg(e.rgb, "picker", false, "primary")
                      }
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="color_picker_head d-flex justify-content-between">
                  <div className="color_picker_head d-flex align-items-center">
                    <span className="color_picker_heading">Primary</span>
                  </div>
                  <div className="color_picker_box">
                    <div
                      className="color-single cursor-pointer"
                      style={{
                        background: design.primaryColor,
                        border: "1px solid black",
                        width: "42px",
                      }}
                      onClick={() =>
                        setPicker({
                          ...picker,
                          primaryPicker: true,
                        })
                      }
                    />
                  </div>
                </div>

                {picker.secondPicker ? (
                  <>
                    <div
                      className="wrapper-picker"
                      onClick={() =>
                        setPicker({
                          ...picker,
                          secondPicker: false,
                        })
                      }
                    />
                    <SketchPicker
                      color={design.secondColor}
                      onChangeComplete={(e) =>
                        handleBg(e.rgb, "picker", false, "secondary")
                      }
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="color_picker_head d-flex justify-content-between mt-3 mb-3">
                  <div className="color_picker_head d-flex align-items-center">
                    <span className="color_picker_heading">Secondary</span>
                  </div>
                  <div className="color_picker_box">
                    <div
                      className="color-single cursor-pointer"
                      style={{
                        background: design.secondColor,
                        border: "1px solid black",
                        width: "42px",
                      }}
                      onClick={() =>
                        setPicker({
                          ...picker,
                          secondPicker: true,
                        })
                      }
                    />
                  </div>
                </div>

                {picker.fontPicker ? (
                  <>
                    <div
                      className="wrapper-picker"
                      onClick={() =>
                        setPicker({
                          ...picker,
                          fontPicker: false,
                        })
                      }
                    />
                    <SketchPicker
                      color={design.fontColor}
                      onChange={(e) =>
                        handleBg(e.rgb, "picker", false, "font")
                      }
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </Collapse>
          </div>

          <div className="secCollapse" style={{ marginTop: "10px" }}>
            <div className="secCollapse-title">
              Logo
              <label className="switch">
                <input
                  type="checkbox"
                  name="logoEnable"
                  checked={design.logoEnable}
                  onChange={(e) => onEnable(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
            {warning.type === "logo" && state.logo === "" ? <><IoIosWarning />{warning.msg} </> : ""}
          </div>
          <div className="toggleBtn">
            <ul>
              <li>
                <h6>Call</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="callEnable"
                    checked={design.callEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "call" && state.phone === "" ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
              <li>
                <h6>Email</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="emailEnable"
                    checked={design.emailEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "email" && state.email === "" ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
              <li>
                <h6>Name</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="nameEnable"
                    checked={design.nameEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "name" && state.name === "" ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
              <li>
                <h6>Address</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="aboutEnable"
                    checked={design.aboutEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "about" && state.address === "" ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
              <li>
                <h6>Description</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="descriptionEnable"
                    checked={design.descriptionEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "description" && state.description === "" ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
              <li>
                <h6>Social Media</h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="socialMediaEnable"
                    checked={design.socialMediaEnable}
                    onChange={(e) => onEnable(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </li>
              <div style={{ color: "red", fontFamily: "roboto" }}>
                {warning.type === "socialMedia" && !state.socialMedia.isSocialMedia ? <><IoIosWarning />{warning.msg} </> : ""}
              </div>
            </ul>
          </div>
        </div>


        <div className="designElem-right" style={{ position: "relative" }}>
          {mainLoader ?
            <div className="design_loader">
              <i
                className="fa fa-spinner fa-spin mx-1"
                style={{ fontSize: "20px" }}
              />
            </div>
            :
            <div className="designPreview_wrapper"
              style={{ background: design.background.includes("http") ? `url(${design.background})` : design.background }}
            >
              <div
                className="designPreview"
              >
                <div className="prevGroup">
                  <div className="prevHead" style={{ background: design.cover.includes("http") ? `url(${design.cover})` : design.cover }}>
                    {design.logoEnable ?
                      < div className="prevUser"><img src={state.logo} alt="" /></div>
                      : ""}
                  </div>
                  <div className="prevGroup-main text-center">
                    <h2 className="pt-2 mt-4">{design.nameEnable ? state.name : ""}</h2>
                    <div className="prevAddress">
                      {design.aboutEnable ?
                        <span>{state.address}</span> : ""
                      }
                    </div>
                    <div className="prevBtnGroup">
                      {design.callEnable ?
                        < button className="blue" onMouseEnter={() => setHover({ email: false, call: true })} onMouseLeave={() => setHover({ email: false, call: false })} style={{ border: "none", marginBottom: "10px", background: hover.call ? design.secondColor : design.primaryColor }}> <IoCallSharp className="mx-2" /> {state.phone}</button>
                        : ""
                      }
                      {design.emailEnable ?
                        <button className="blue" onMouseEnter={() => setHover({ call: false, email: true })} onMouseLeave={() => setHover({ call: false, email: false })} style={{ border: "none", background: hover.email ? design.secondColor : design.primaryColor }}><FaEnvelope className="mx-2" />{state.email}</button>
                        : ""
                      }
                    </div>
                  </div>
                </div>

                {design.descriptionEnable ?
                  <div className="prevGroup mt-3">
                    <div className="prevGroup-main">
                      <h2>About</h2>
                      <div className="skillGroup">
                        <span>{state.description}</span>
                      </div>
                    </div>
                  </div> : ""
                }

                <div className="prevGroup mt-3">
                  <div className="prevGroup-main">
                    <h2>Products</h2>
                    <div className="proGroup">
                      <div className="row">
                        <div className="col-xl-4">
                          <div className="proGroup-single">
                            <div className="proGroup-img"><img src={productImg} alt="" /></div>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="proGroup-single">
                            <div className="proGroup-img"><img src={productImg} alt="" /></div>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="proGroup-single">
                            <div className="proGroup-img"><img src={productImg} alt="" /></div>
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="proGroup-single">
                            <div className="proGroup-img"><img src={productImg} alt="" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div className="FooterprevGroup mt-2">
                <div className="prevGroup-main p-3">
                  <div className="row">
                    <div className="col-xl-12">
                      <footer className="d-flex justify-content-between">
                        <span
                          style={{
                            fontSize: "13px",
                            color: '#000',
                            fontWeight: '600',
                            paddingLeft: '20px',
                            margin: design.socialMediaEnable ? "" : "0 auto"
                          }}

                        >© 2023, All Rights Reserved to {appName}</span>
                        {design.socialMediaEnable ?
                          <ul className="d-flex justify-content-evenly">
                            {state.socialMedia.facebook ? <li className="me-3"><a href={state.socialMedia.facebook} target="_blank"><i className="fa-brands fa-facebook" style={{ color: "rgb(0 45 148)", fontSize: "20px" }}></i></a></li> : ""}
                            {state.socialMedia.youtube ? <li className="me-3"><a href={state.socialMedia.youtube} target="_blank"><i className="fa-brands fa-youtube"
                              style={{ color: "rgb(0 45 148)", fontSize: "20px" }}></i></a></li> : ""}
                            {state.socialMedia.instagram ? <li className="me-3"><a href={state.socialMedia.instagram} target="_blank"><i className="fa-brands fa-instagram"
                              style={{ color: "rgb(0 45 148)", fontSize: "20px" }}></i></a></li> : ""}
                            {state.socialMedia.twitter ? <li className="me-3"><a href={state.socialMedia.twitter} target="_blank"> <i className="fa-brands fa-twitter" style={{ color: "rgb(0 45 148)", fontSize: "20px" }}></i></a></li> : ""}
                          </ul>
                          : ""}
                      </footer>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          }

        </div>

      </div>
    </>
  );
};

export default BusinessDesign;
