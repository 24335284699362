import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import ModernDatepicker from "react-modern-datepicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  saveCoupon,
  updateCoupon,
  viewCoupon,
} from "../../Actions/CouponActioin";
import { fetchProductsCustomer } from "../../Actions/ClientAction";
import { FaBuilding } from "react-icons/fa";
import { setAlert } from "../../Actions/AlertAction";
import { useEffect } from "react";
import queryString from "query-string";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { MdError } from "react-icons/md";

const CreateCoupon = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  const editId = queryString.parse(location.search).id;
  const bid = queryString.parse(location.search).bid;

  const [coupon, setCoupon] = useState({
    couponCode: "",
    couponStart: "",
    couponTo: "",
    couponType: "Percentage",
    couponAmount: "",
    // couponDescription: "",
    status: "1",
  });

  const [loader, setLoader] = useState({
    loader: false,
    mainLoader: false,
  });
  const [id, setId] = useState(null);
  const [product, setProduct] = useState([]);
  const [dateMsg, setDateMsg] = useState(false);
  const [dateMsgEnd, setDateMsgEnd] = useState(false);
  const [isExpired,setIsExpired] = useState('')

  const handleChange = (e) => {
    const { value, name } = e.target;

    let val = value;
    if (name === "couponAmount") {
      val = value.match("^[0-9.]*$");
      val = val[0];
      if (coupon.couponType === "Percentage") {
        if (val < 100) {
          setCoupon({
            ...coupon,
            [name]: val,
          });
        } else {
          dispatch(setAlert("Discount should be less than 100%", "danger"));
        }
      } else {
        setCoupon({
          ...coupon,
          [name]: val,
        });
      }
    } else if (name === "couponType") {
      setCoupon({
        ...coupon,
        couponAmount: "",
        couponType: val,
      });
    } else {
      setCoupon({
        ...coupon,
        [name]: val,
      });
    }
    setDateMsg(false);
    setDateMsgEnd(false);
  };

  const fetchProducts = () => {
    let data = { id: bid };
    dispatch(fetchProductsCustomer(data, setProduct, loader, setLoader));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleCouponChange = (e) => {
    const { name, checked } = e.target;
    if (name === "applyCoupon") {
      if (checked === false) {
        setCoupon({
          ...coupon,
          status: "1",
        });
      } else {
        setCoupon({
          ...coupon,
          status: "0",
        });
      }
    }
  };

  const handleDate = (date, type) => {
    if (type === "start") {
      setCoupon({
        ...coupon,
        couponStart: date,
      });
    } else if ((type = "end" && coupon.couponStart !== date)) {
      setCoupon({
        ...coupon,
        couponTo: date,
      });
    } else {
      dispatch(setAlert("Should'nt choose same date", "danger"));
    }
    setDateMsg(false);
    setDateMsgEnd(false);
  };

  const navigate = useNavigate();

  const handleSave = (e) => {
    e.preventDefault();
    if (editId) {
      setLoader({
        ...loader,
        loader: true,
      });
      let data = { id, ...coupon };
      dispatch(updateCoupon(data, setLoader, loader, navigate,bid));
    } else {
      if (coupon.couponStart === "") {
        setDateMsg(true);
      } else if (coupon.couponTo === "") {
        setDateMsgEnd(true);
      } else {
        let data = { ...coupon };
        setLoader({
          ...loader,
          loader: true,
        });
        dispatch(saveCoupon(data, setLoader, navigate,bid));
      }
    }
  };

  useEffect(() => {
    handleEdit();
  }, [editId]);

  useEffect(()=>{
    if(coupon.couponTo){
    getCoupon()
    }
  },[coupon])

 

  const getCoupon=()=>{
    if(coupon.couponTo){
    const dateStr = coupon.couponTo;

    const dateObj = moment(dateStr, 'DD-MM-YYYY').toDate();
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const newDateStr = `${year}-${month}-${day}`;
    const expirationDate = moment().format("YYYY-MM-DD");
    const data =expirationDate < newDateStr;
    setIsExpired(data)
    if(expirationDate == newDateStr){
      setIsExpired(true)
    }else if (expirationDate<newDateStr){
      setIsExpired(true)
    }else{
      setIsExpired(false)
    }

    // const todayDate= new Date();
    // const todaySec= todayDate.getTime()
    // console.log(todaySec)
    // const expDate=dateObj
    // const expSec = expDate.getTime()
    // console.log(expSec)
    // if(todaySec > expSec){
    //     setIsExpired(false)
    //     console.log("equal")
    //   }else if (todaySec<expSec){
    //     setIsExpired(true)
    //     console.log("equal1")
    //   }else{
    //     setIsExpired(true)
    //     console.log("equal2")
    //   }
      }
  }


  const handleEdit = () => {
    if (editId) {
      setLoader({
        ...loader,
        mainLoader: true,
      });
      let data = { id: editId };
      dispatch(viewCoupon(data, setCoupon, loader, setLoader));
    }

    setId(editId);
  };

  return (
    <>
      {loader.mainLoader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {" "}
          <TitleBar title="Create Coupon" />
          <Navbar activeNav={"business"} />
          <section className="siteWrap">
            <div className="container">
              <div className="business-wrap">
                <ViewBusinessNav active={"coupons"}/>
                <div className="business-top">
                  <h2>{editId ? "Edit Coupon" : "Create Coupon"}</h2>
                </div>
                <form onSubmit={handleSave}>
                  <div className="business-list">
                    <div className="inpLabelWrap mt-0">
                      <span className="labelTxt">Coupon Code</span>
                      <div className="inpLabel">
                        <label htmlFor="">Coupon Code</label>
                        <input
                          className="inpLabel-inp"
                          name="couponCode"
                          value={coupon.couponCode}
                          onChange={(e) => handleChange(e)}
                          placeholder="Add Coupon Code"
                          type="text"
                          maxLength={100}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Coupon Start From</span>

                          <div className="zindex-popover inpLabel-inp">
                            <ModernDatepicker
                              date={coupon.couponStart}
                              format={"DD-MM-YYYY"}
                              className="date-picker-style"
                              onChange={(date) => handleDate(date, "start")}
                              primaryColor={"#1755d4"}
                              secondaryColor={"#fff6fe"}
                              primaryTextColor={"#0053F"}
                              secondaryTextColor={"#fff"}
                              placeholder="DD-MM-YYYY"
                               minDate={editId?"": moment().format("DD-MM-YYYY ")}
                              required
                              style={{
                                border: `1px solid ${
                                  dateMsg ? "red" : "#bed1ff"
                                }`,
                              }}
                            
                            />
                          </div>
                          <div className="inpLabelWrap m-0">
                            {dateMsg ? (
                              <p style={{ color: "red" }}>
                                <MdError
                                  style={{
                                    color: "red",
                                    marginTop: "-4px",
                                    fontSize: "20px",
                                  }}
                                />
                                This field is required
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Coupon Ends On</span>
                          <div className="inpLabel-inp"  style={{
                                border: `1px solid ${
                                  editId?isExpired ? "#bed1ff" : "red":"#bed1ff"
                                }`,
                              }}>
                            {/* <label htmlFor="">Coupon Ends On</label> */}
                            <ModernDatepicker
                              date={coupon.couponTo}
                              format={"DD-MM-YYYY"}
                              className="date-picker-style"
                              onChange={(date) => handleDate(date, "end")}
                              primaryColor={"#1755d4"}
                              secondaryColor={"#fff6fe"}
                              primaryTextColor={"#0053F"}
                              secondaryTextColor={"#fff"}
                              placeholder="DD-MM-YYYY"
                               minDate={editId?"": moment().format("DD-MM-YYYY ")}
                              required
                              style={{
                                border: `1px solid ${
                                  dateMsg ? "red" : "#bed1ff"
                                }`,
                              }}
                            />
                          </div>
                          <div className="inpLabelWrap m-0">
                            {editId?!isExpired ? (
                              <p style={{ color: "red" }}>
                                <MdError
                                  style={{
                                    color: "red",
                                    marginTop: "-4px",
                                    fontSize: "20px",
                                  }}
                                />
                                Coupon is expired
                              </p>
                            ) : (
                              ""
                            ):""}
                          </div>
                          <div className="inpLabelWrap m-0">
                            {dateMsgEnd ? (
                              <p style={{ color: "red" }}>
                                <MdError
                                  style={{
                                    color: "red",
                                    marginTop: "-4px",
                                    fontSize: "20px",
                                  }}
                                />
                                This field is required
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inpLabel d-flex justify-content-start">
                      <label htmlFor="">Coupon Type</label>
                      <div className="d-flex">
                        <div className="custom-radio">
                          Percentage
                          <input
                            type="radio"
                            value="Percentage"
                            checked={
                              coupon.couponType === "Percentage" ? true : false
                            }
                            id="percent"
                            name="couponType"
                            onChange={(e) => handleChange(e)}
                          />
                          <span class="checkmark"></span>
                        </div>
                        <div className="custom-radio">
                          Flat Rate
                          <input
                            type="radio"
                            name="couponType"
                            value="Flat Rate"
                            id="flat"
                            checked={
                              coupon.couponType === "Flat Rate" ? true : false
                            }
                            onChange={(e) => handleChange(e)}
                          />
                          <span class="checkmark"></span>
                        </div>
                      </div>
                    </div>

                    <div className="inpLabelWrap">
                      <span className="labelTxt">
                        {" "}
                        {coupon.couponType === "Percentage"
                          ? "Discount Percentage"
                          : "Discount Amount"}
                      </span>
                      <div className="inpLabel">
                        <label htmlFor="">
                          {coupon.couponType === "Percentage"
                            ? "Discount Percentage"
                            : "Discount Amount"}
                        </label>
                        <input
                          className="inpLabel-inp"
                          name="couponAmount"
                          value={coupon.couponAmount}
                          onChange={(e) => handleChange(e)}
                          placeholder={
                            coupon.couponType === "Percentage"
                              ? "Discount Percentage"
                              : "Discount Amount"
                          }
                          type="text"
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="locateBox full">
                          <div>Deactivate for all Products</div>
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="applyCoupon"
                                checked={coupon.status === "0" ? true : false}
                                onChange={(e) => handleCouponChange(e)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {editId ? (
                      <div className="btn-sec text-right mt-4">
                        <button
                       type="submit"
                          className="demoLink"
                        >
                          {loader.loader ? (
                            <>
                              Updating Coupon
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Update Coupon"
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="btn-sec text-right mt-4">
                        <button className="demoLink" type="submit">
                          {loader.loader ? (
                            <>
                              Saving Coupon
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Save Coupon"
                          )}{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default CreateCoupon;
