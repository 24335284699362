import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const createStaff =
  (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-staff", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setLoader(false);
          navigate("/staff");
        } else {
          dispatch(setAlert(res.msg, "danger"));
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const listStaffData =
  (data, setStaff, setLoader) => (dispatch, getState) => {
    commonAxios("list-staff", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setStaff(res.data);
          dispatch(setAlert(res.msg, "succes"));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const fetchStaffData =
  (data, setStaff, setMainLoader) => (dispatch, getState) => {
    commonAxios("view-staff", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.data) {
          let obj = {
            name: res.data[0].name,
            email: res.data[0].email,
            status:res.data[0].status,
            bid: JSON.parse(res.data[0].bid),
            pid: JSON.parse(res.data[0].pid),
         
          };
          setStaff(obj);
          setMainLoader(false)
        }
         else {
          dispatch(setAlert(res.msg, "danger"));
          setMainLoader(false)
        }
        setMainLoader(false)
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setMainLoader(false)
      });
  };

export const updateStaffData =
  (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("update-staff", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setLoader(false);
          navigate("/staff");
        } else {
          dispatch(setAlert(res.msg, "danger"));
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const deleteStaff =
  (data, setLoader, fetchStaff) => (dispatch, getState) => {
    commonAxios("delete-staff", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setLoader(false);
          fetchStaff();
        } else {
          dispatch(setAlert(res.msg, "danger"));
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg));
        setLoader(false);
      });
  };

export const updateStaffPass = (data, setLoader) => (dispatch, getState) => {
  commonAxios("edit-client-account", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};
