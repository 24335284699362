import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ProductDesign from "./ProductDesign";
import ProductGeneral from "./ProductGeneral";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { onGetProduct } from "../../Actions/ProductAction";
import { useDispatch } from "react-redux";
import ViewBusinessNav from "../Business/ViewBusinessNav";

const CreateProduct = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pid = queryString.parse(location.search).pid;
  const bid = queryString.parse(location.search).bid;
  const design = queryString.parse(location.search).design;
  let [loader, setLoader] = useState({
    mainLoader: false
  });
  const fetchProductData = () => {
    setLoader({
      ...loader,
      mainLoader: true
    })
    let data = {
      id: pid,
    };
    dispatch(onGetProduct(data, loader, setLoader, navigate, bid));
  };

  useEffect(() => {
    if (pid) {
      fetchProductData();
    }
  }, [pid]);

  return (
    <>
      <TitleBar title="Business" />
      <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <ViewBusinessNav active={"product"} />
            <div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={design === "true" ? "tab2" : "tab1"}
              >
                <Nav className="navSet nav-fill">
                  <Nav.Item>
                    <Nav.Link eventKey="tab1">General</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tab2">Design</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="productTabs">
                  <Tab.Pane eventKey="tab1">
                    <ProductGeneral pid={pid} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    <ProductDesign mainLoader={loader.mainLoader} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateProduct;
