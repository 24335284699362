import React from "react";
import { useSelector } from "react-redux";
import ConnectReelapps from "../Integration/ConnectReelapps";
import paypal from "../../images/paypal.png";
import stripe from "../../images/Stripe.png";
import razorpay from "../../images/razorpay.png";
import mailChimp_logo from "../../images/mail-chimp.png";
import getResponse_logo from "../../images/get-response.webp";
import awebar_logo from "../../images/AWeber.png";
import Sendgrid_logo from "../../images/Sendgrid.png";
import Sendiio_logo from "../../images/Sandiio_logo1.png";
import go_to_web from "../../images/GoToWebinar.png";
import zoomlogo from "../../images/zoomlogo.png";
import Mailvio_logo from '../../images/Mailvio_logo.png'
import twilio from  '../../images/twilio.png'


const BusinessIntegration = () => {

    const socialData = useSelector(state => state.business.integration);
    return (
        <>
            <div className="siteWrapn">
                <h2 className="titleBdr mb-3" style={{ color: "#676767" }}>My Payment Integration</h2>
                <ConnectReelapps
                    icon={paypal}
                    name="Paypal"
                    type="paypal"
                    data={socialData.paypal}
                    activeFor="payment"
                />
                <ConnectReelapps
                    icon={stripe}
                    name="Stripe"
                    type="stripe"
                    data={socialData.stripe}
                    activeFor="payment"

                />
                <ConnectReelapps
                    icon={razorpay}
                    name="Razorpay"
                    type="razorpay"
                    data={socialData.razorPay}
                    activeFor="payment"

                />
            </div>

            <div className="siteWrapn mt-5 pt-3 mb-3">
                <h2 className="titleBdr" style={{ color: "#676767" }}>My Auto Responder Integration</h2>
                <ConnectReelapps icon={getResponse_logo} name="GetResponse" type="gr" data={socialData.getResponse} />
                <ConnectReelapps icon={awebar_logo} name="Aweber" type="aw" data={socialData.aweber} />
                {/* <ConnectReelapps icon={Sendgrid_logo} name="Sendgrid" type="sg" data={socialData.sendgrid} /> */}
                <ConnectReelapps icon={Sendiio_logo} name="Sendiio" type="si" data={socialData.sendiio} />
                <ConnectReelapps icon={mailChimp_logo} name="MailChimp" type="mc" data={socialData.mailchip} />
                <ConnectReelapps icon={Mailvio_logo} name="Mailvio" type="mv" data={socialData.mailvio} />
            </div>

            <div className="siteWrapn mt-5 pt-3 mb-3">
                <h2 className="titleBdr" style={{ color: "#676767" }}>My Webinar Integration</h2>
                <ConnectReelapps icon={go_to_web} name="GoToWebinar" type="GoToWebinar" data={socialData.gotoWebinar} />
                <ConnectReelapps icon={zoomlogo} name="Zoom" type="zoom" data={socialData.zoom} />

            </div>

            <div className="siteWrapn mt-5 pt-3 mb-3">
                <h2 className="titleBdr" style={{ color: "#676767" }}>My SMS gateway Integration</h2>
                <ConnectReelapps icon={twilio} name="Twilio" type="twilio" activeFor="twilio" data={socialData.twilio} />

            </div>
        </>
    )
}

export default BusinessIntegration;