import { setAlert } from "./AlertAction";
import { commonAxios } from "../Global/CommonAxios";

export const fetchRebrandData = () => (dispatch, getState) => {
  commonAxios("fetch-wl-rebranding", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        dispatch({ type: "ADD_REBRAND", payload: res.data });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addRebrandData =
  (data, setLoader, setFileData, setName) => (dispatch, getState) => {
    commonAxios("wl-rebranding", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          dispatch(fetchRebrandData());
          setFileData("");
          setName("");
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

export const onGetButtonData =
  (setState, setLoader) => (dispatch, getState) => {
    commonAxios("total-client", {}, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setState(res.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

export const onCreateInvoice =
  (data, setLoader, handleClose,setState, setShowInvoice) => (dispatch, getState) => {
    let obj = {
      bid: data.business,
      pid: data.product,
      cid: data.customer,
    };
    commonAxios("generate-invoice", obj, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setState({
            ...data,
            business: "",
            product: "",
            customer: "",
          });
          setShowInvoice(true)
        } else {
          dispatch(setAlert(res.msg, "danger"));
          handleClose();
          setState({
            ...data,
            business: "",
            product: "",
            customer: "",
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        handleClose();
        setState([]);
      });
  };

export const removeRebrandData = () => (dispatch) => {
  dispatch({ type: "REMOVE_ADD_REBRAND" });
};
