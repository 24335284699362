import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  createStaff,
  updateStaffData,
  fetchStaffData,
  updateStaffPass,
} from "../../Actions/StaffAction";
import { onFetchBusinessList } from "../../Actions/BusinessAction";
import { onFetchProducts } from "../../Actions/ProductAction";

import { useDispatch } from "react-redux";
import queryString from "query-string";
import { MdError } from "react-icons/md";
import { updatePassword } from "../../Actions/AuthAction";
import Select from "react-select";

const CreateStaff = () => {
  const location = useLocation();
  const staffId = queryString.parse(location.search).staffId;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [staff, setStaff] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    bid: [],
    pid: [],
    status: true,
  });

  const [password, setPassword] = useState({
    confirm_password: "",
    password: "",
    // password: "",
  });
  const [loader, setLoader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [passLoader, setPassLoader] = useState(false);
  const [msg, setMsg] = useState(false);
  const [opationData, setOpationData] = useState([]);
  const [productName, setProductName] = useState([]);
  const [defaultBus, setDefaultBus] = useState([]);
  const [defaultPro, setDefaultPro] = useState([]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setStaff({
      ...staff,
      [name]: value,
    });
    setMsg(false);
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    // let val = checked ? "active" : "deactivate";
    setStaff({
      ...staff,
      [name]: checked,
    });
  };

  const handleChangePass = (e) => {
    const { name, value } = e.target;
    if (msg) {
      setMsg(false);
    }
    setPassword({
      ...password,
      [name]: value,
    });
  };

  const fetcBusinessName = () => {
    setLoader(true);
    dispatch(onFetchBusinessList(setOpationData, setLoader, true));
  };

  const fetchProductName = () => {
    let data = {};
    dispatch(onFetchProducts(data, setProductName, setLoader, true));
  };

  useEffect(() => {
    fetcBusinessName();
    fetchProductName();
  }, []);

  const handleSelectChange = (e, type) => {
    setStaff({
      ...staff,
      [type]: e,
    });
  };

  const defaultProValue = () => {
    let objArr = [];
    staff.pid.forEach((elem) => {
      let temp = {
        label: elem.label,
        value: elem.value,
      };
      objArr.push(temp);
    });
    setDefaultPro(objArr);
  };


  const defaultBusValue = () => {
    let objArr1 = [];
    staff.bid.forEach((elem) => {
      let temp = {
        label: elem.label,
        value: elem.value,
      };
      objArr1.push(temp);
    });
    setDefaultBus(objArr1);
  };

  const handleEditStaff = () => {
    if (staffId) {
      setMainLoader(true);
      let data = { id: staffId };
      dispatch(fetchStaffData(data, setStaff, setMainLoader, loader));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (staffId) {
      let data = { ...staff };
      data.bid = JSON.stringify(data.bid);
      data.pid = JSON.stringify(data.pid);
      delete data.password;
      delete data.confirmPassword;
      data.staffId = staffId;
      setLoader(true);
      dispatch(updateStaffData(data, setLoader, navigate));
    } else {
      if (staff.password !== staff.confirmPassword) {
        setMsg(true);
      } else {
        let data = { ...staff };
        data.bid = JSON.stringify(data.bid);
        data.pid = JSON.stringify(data.pid);
        setLoader(true);
        dispatch(createStaff(data, navigate, setLoader));
      }
    }
  };

  const onUpdatePassword = (e) => {
   e.preventDefault();
    if (password.password !== password.confirm_password) {
      setMsg(true);
    } else {
      setPassLoader(true);
      let data = {...password};
      data.id = staffId;
      data.name = staff.name;
      delete data.confirm_password;
      dispatch(updateStaffPass(data, setPassLoader));
      setMsg(false);
    }
  };

  useEffect(() => {
    if (staff.bid.length > 0) {
      defaultBusValue();
    } else {
      setDefaultBus([]);
    }
    if (staff.pid.length > 0) {
      defaultProValue();
    } else {
      setDefaultPro([]);
    }
  }, [staff]);

  useEffect(() => {
    handleEditStaff();
  }, [staffId]);

  return (
    <>
      {mainLoader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <TitleBar title="Business" />
          <Navbar activeNav={"staff"} />
          <section className="siteWrap">
            <div className="container">
              <div className="business-wrap">
                <div className="business-top">
                  <h2>{staffId ? "Edit Staff" : "Create Staff"}</h2>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="business-list">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                          <span className="labelTxt">Name</span>
                          <div className="inpLabel">
                            <label htmlFor="">Name <span  style={{
                                  float: "right",
                                  fontweight: "normal",
                                  marginRight: "10px",
                                }}>   {staff.name.length} / 100</span></label>
                            <input
                              className="inpLabel-inp"
                              placeholder="Add Name"
                              type="text"
                              name="name"
                              onChange={(e) => handleChange(e)}
                              value={staff.name}
                              required
                              maxLength={100}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                          <span className="labelTxt">Email</span>
                          <div className="inpLabel">
                            <label htmlFor="">Email</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="Add Email"
                              type="Email"
                              name="email"
                              onChange={(e) => handleChange(e)}
                              value={staff.email}
                              required
                              maxLength={50}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`row ${staffId ? "d-none" : ""}`}>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Password</span>
                          <div className="inpLabel">
                            <label htmlFor="">Password</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="Password"
                              type="password"
                              name="password"
                              onChange={(e) => handleChange(e)}
                              value={staff.password}
                              maxLength={50}
                              required={staffId ? false : true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Confirm Password</span>
                          <div className="inpLabel">
                            <label htmlFor="">Confirm Password</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="Confirm Password"
                              type="password"
                              name="confirmPassword"
                              onChange={(e) => handleChange(e)}
                              value={staff.confirmPassword}
                              style={{
                                border: `1px solid ${msg ? "red" : "#bed1ff"}`,
                              }}
                              required={staffId ? false : true}
                              maxLength={50}
                            />
                          </div>
                          <div className="inpLabelWrap m-0">
                            {msg ? (
                              <p style={{ color: "red" }}>
                                <MdError
                                  style={{
                                    color: "red",
                                    marginTop: "-4px",
                                    fontSize: "20px",
                                  }}
                                />
                                Password not matched
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Business</span>
                          <div className="inpLabel">
                            <Select
                              className="inpLabel-inp multiselect"
                              closeMenuOnSelect={true}
                              value={defaultBus}
                              isMulti
                              options={opationData}
                              onChange={(e) => handleSelectChange(e, "bid")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Products</span>
                          <div className="inpLabel">
                            <Select
                              className="inpLabel-inp multiselect"
                              closeMenuOnSelect={true}
                              value={defaultPro}
                              isMulti
                              options={productName}
                              onChange={(e) => handleSelectChange(e, "pid")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" inpLabel d-flex locateBox justify-content-between">
                      <label htmlFor="">Status</label>
                      <div className="d-flex">
                        <label className="switch flex">
                          <input
                            type="checkbox"
                            name="status"
                            checked={+staff.status}
                            onChange={(e) => handleToggle(e)}
                          />
                          <span className="slider round"></span>
                          <span className="slClose">Deactivate</span>
                          <span className="slOpen">Active</span>
                        </label>
                      </div>
                    </div>

                    <div className="btn-sec text-right mt-4">
                      {staffId ? (
                        <button type="submit" className="demoLink">
                          {loader ? (
                            <>
                              Updating Staff
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Update Staff"
                          )}
                        </button>
                      ) : (
                        <button type="submit" className="demoLink">
                          {loader ? (
                            <>
                              Creating Staff
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Create Staff"
                          )}
                        </button>
                      )}
                    </div>
                    </div>
                </form>

                   <div className="business-list">
                    <div className="row">
                    <form
                      className={`row ${staffId ? "" : "d-none"}`}
                      onSubmit={onUpdatePassword}
                    >
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">New Password</span>
                          <div className="inpLabel">
                            <label htmlFor="">New Password</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="New Password"
                              type="password"
                              name="password"
                              onChange={(e) => handleChangePass(e)}
                              value={password.password}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Confirm Password</span>
                          <div className="inpLabel">
                            <label htmlFor="">Confirm Password</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="Confirm Password"
                              type="password"
                              name="confirm_password"
                              onChange={(e) => handleChangePass(e)}
                              value={password.confirm_password}
                              style={{
                                border: `1px solid ${msg ? "red" : "#bed1ff"}`,
                              }}
                            />
                          </div>
                          <div className="inpLabelWrap m-0">
                            {msg ? (
                              <p style={{ color: "red" }}>
                                <MdError
                                  style={{
                                    color: "red",
                                    marginTop: "-4px",
                                    fontSize: "20px",
                                  }}
                                />
                                Password not matched
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="btn-sec text-right mt-4">
                        <button type="submit" className="demoLink">
                          {passLoader ? (
                            <>
                              Updating Password
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Update Password"
                          )}
                        </button>
                      </div>
                    </form>
                    </div>
                   </div>
                  
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default CreateStaff;
