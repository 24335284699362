import produce from "immer";
const initialState = {
  data: {
    name: "",
    email: "",
    phone: "",
    bid: "",
    pid: "",
  },
};

export const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CLIENT_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.value,
        },
      };

    case "SET_CUSTOMER_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          name: action.payload.name,
          email: action.payload.email,
          phone: action.payload.isPhone,
        },
      };


    case "UNMOUNT_CLIENT":
      return initialState;

    default:
      return state;
  }
};
