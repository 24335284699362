import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi";
import { BsClockHistory } from "react-icons/bs";
import { fetchRemainder, deleteReminder, sendReminder } from "../../Actions/Remainder.Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

const Reminders = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bid = queryString.parse(location.search).bid;
  const auth = useSelector((state) => state.auth);
  const symbol = auth.user.symbol;

  const [remainder, setRemainder] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [pageNumber, setPageNumber] = useState([]);
  const [remainderData, setRemainderData] = useState([]);

  const [state, setState] = useState({
    name: false,
    customerName: false,
    productName: false,
    createdDate: false,
  });

  const fetchRemainderData = () => {
    setLoader(true);
    let data = { bid: bid };
    dispatch(fetchRemainder(data, setRemainder, setLoader));
  };

  useEffect(() => {
    fetchRemainderData();
  }, []);

  const handleSort = (type) => {
    let data = [...remainder];
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    } else if (type === "created") {
      if (state.createdDate) {
        data.sort((a, b) => {
          if (a.created.toLowerCase() < b.created.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          createdDate: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.created.toLowerCase() > b.created.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          createdDate: true,
        });
      }
    } else if (type === "customerName") {
      if (state.customerName) {
        data.sort((a, b) => {
          if (
            a.customerInfo[0].name.toLowerCase() <
            b.customerInfo[0].name.toLowerCase()
          ) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          customerName: false,
        });
      } else {
        data.sort((a, b) => {
          if (
            a.customerInfo[0].name.toLowerCase() >
            b.customerInfo[0].name.toLowerCase()
          ) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          customerName: true,
        });
      }
    }
    setRemainder(data);
  };

  const handleEdit = (id) => {
    navigate(`/create-reminders?bid=${bid}&id=${id}`);
  };

  const handleDelete = (id) => {
    let data = { id: id };
    // setLoader(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        dispatch(deleteReminder(data, fetchRemainderData));
      }
    });
  };

  const handleRemainder = (elem) => {
    let data = {
      rid: elem.id,
      bid: elem.bid,
      cid: elem.cid,
      pid: elem.pid
    };
    console.log(elem.rid)
    Swal.fire({
      title: "Are you sure?",
      text: "Send a reminder!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sending...",
          timer: 1000,
        });
        dispatch(sendReminder(data));
      }
    });
  }

  const handleSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "0") {
      setMode("email");
    } else if (value === "1") {
      setMode("sms");
    }
  };

  const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexofFirsTodo = indexofLastTodo - pagination.totalItemonPage;
  const currentTodo =
    (search === "" && mode !== "") ||
      (search !== "" && mode === "") ||
      (search !== "" && mode !== "")
      ? remainderData
      : remainderData.slice(indexofFirsTodo, indexofLastTodo);

  useEffect(() => {
    if (remainderData.length > 0) {
      let maxLength = remainderData.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [remainderData]);

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };
  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };

  useEffect(() => {
    if (search === "" && mode === "") {
      setRemainderData(remainder);
    } else {
      const val = remainder
        .filter((curElem) => {
          if (mode === "") {
            return curElem;
          } else {
            let data = curElem.type === mode;
            return data;
          }
        })
        .filter((curElem) => {
          let data = curElem.name.toLowerCase().includes(search.toLowerCase());
          if (data) {
            return curElem;
          }
        });
      setRemainderData(val);
    }
  }, [remainder, mode, search]);

  return (
    <>
      <TitleBar title="Coupon" />
      <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <ViewBusinessNav />

            <div className="business-top">
              <h2>Your Reminder</h2>
              <Link
                className="demoLink mt-0"
                to={`/create-reminders?bid=${bid}`}
              >
                Create Reminder <FiArrowRight />{" "}
              </Link>
            </div>
            <div className="business-list">
              <div className="business-search">
                <div className="business-search-inp">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    className="inpSearch withIcon"
                    type="text"
                    placeholder="Search reminder name"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="business-search-inp ml-2">
                  <select
                    name=""
                    id=""
                    onChange={handleSelect}
                    className="inpSearch"
                  >
                    <option value="">All</option>
                    <option value={0}>Email</option>
                    <option value={1}>SMS</option>
                  </select>
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <thead>
                    <tr>
                      {/* <th width="150"></th> */}
                      <th
                        onClick={() => handleSort("name")}
                        className="cursor-pointer ml-2"
                      >
                        Name
                      </th>
                      <th
                        onClick={() => handleSort("customerName")}
                        className="cursor-pointer ml-2"
                      >
                        Customer Name
                      </th>
                      <th
                        onClick={() => handleSort("productName")}
                        className="cursor-pointer ml-2"
                      >
                        Product Name
                      </th>
                      <th>Type</th>
                      <th>Price ({symbol})</th>
                      <th
                        onClick={() => handleSort("created")}
                        className="cursor-pointer"
                      >
                        Created
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentTodo.length > 0
                      ? currentTodo.map((curElem, index) => {
                        return (
                          <tr>
                            <td>{curElem.name}</td>

                            {/* <td>Business Name</td> */}
                            <td>
                              {curElem.customerInfo
                                ? curElem.customerInfo[0].name
                                : ""}
                            </td>
                            <td>
                              {curElem.productInfo
                                ? curElem.productInfo[0].name
                                : ""}
                            </td>
                            <td>
                              {curElem.type === "email" ? "Email" : "SMS"}
                            </td>
                            <td>
                              {curElem.productInfo
                                ? curElem.productInfo[0].price
                                : ""}
                            </td>
                            <td>{curElem.created.split(" ", 1)}</td>
                            <td>
                              <a>
                                <BsClockHistory
                                  onClick={() => handleRemainder(curElem)}
                                  className="smIcon cursor-pointer"
                                  title="Send Reminder"
                                />
                              </a>
                              <a>
                                <FiEdit
                                  className="smIcon cursor-pointer mx-2"
                                  onClick={() => handleEdit(curElem.id)}
                                  title="Edit"
                                />
                              </a>
                              <a>
                                <FiTrash2
                                  className="smIcon cursor-pointer"
                                  onClick={() => handleDelete(curElem.id)}
                                  title="Delete"
                                />
                              </a>

                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>

                {loader === true ? (
                  ""
                ) : remainder.length === 0 ? (
                  ""
                ) : currentTodo.length !== 0 ? (
                  ""
                ) : (
                  <div className="text-center mt-4 text-capitalize">
                    "No Data Found"
                  </div>
                )}

                {loader ? (
                  <div className="text-center my-4">
                    <i
                      className="fa fa-spinner fa-spin mr-2"
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                ) : remainder.length === 0 ? (
                  <div className="text-center mt-4 ">
                    "You have not created any Reminders yet"
                  </div>
                ) : (
                  ""
                )}

                <div className="tablepage">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>

                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                        return (
                          <li
                            key={index}
                            id={curElem}
                            style={{ color: "white" }}
                            onClick={() => handleClick(curElem)}
                            className="cursor-pointer"
                          >
                            <a
                              style={
                                curElem === pagination.currentPage
                                  ? {
                                    backgroundColor: "white",
                                    color: "#400C46",
                                  }
                                  : {}
                              }
                            >
                              {curElem}
                            </a>
                          </li>
                        );
                      })
                      : ""}
                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Reminders;
