import React, { useEffect } from "react";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import { useDispatch } from "react-redux";
import Navbar from "../Navbar";


const Dashboard = () => {
    const dispatch = useDispatch()

    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />
            <section className="siteWrap">
                <DashboardHead />
                <DashboardContent />
                {/* <DashboardAbout /> */}
            </section>
            {/* <Webinar /> */}
            <Footer />
        </>
    )
}

export default Dashboard;