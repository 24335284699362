import queryString from 'query-string';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { onConnectPayment } from '../../Actions/BusinessAction';
import modalCloseIcon from '../../images/modal-close.png';

const IntegrationPopup = (props) => {
    let location = useLocation()
    let bId = queryString.parse(location.search).bid
    const business = useSelector(state => state.business.data)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        name: "",
        publicKey: "",
        privateKey: "",
        livePublicKey: "",
        livePrivateKey: "",
        bid: bId,
        type: business.mode
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let ep = ""
        if (props.name === "Paypal") {
            ep = "paypal-auth"
        } else if (props.name === "Stripe") {
            ep = "stripe-integration"
        } else if (props.name === "Razorpay") {
            ep = "razorpay-auth"
        }
        dispatch(onConnectPayment(ep, state, setLoader, props.handleClose))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        if (business.integration) {
            setState({
                ...state,
                name: "",
                publicKey: "",
                privateKey: "",
                livePublicKey: "",
                livePrivateKey: "",
                bid: bId,
                type: business.mode
            })
        }
    }, [business.integration])

    return (
        <Modal className="VideoModal small" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <h4 style={{ color: "#000000" }}>{props.name} Payment Integration </h4>
                    <hr style={{ color: "#000", opacity: 1 }} />
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="name">Name</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Name"
                                        type="text"
                                        name='name'
                                        value={state.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="clientId">Public Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Client ID"
                                        type="text"
                                        name='publicKey'
                                        value={state.publicKey}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="secretId">Private Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Secret ID"
                                        type="text"
                                        name='privateKey'
                                        value={state.privateKey}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="secretId">Live Public Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Live Client ID"
                                        type="text"
                                        name='livePublicKey'
                                        value={state.livePublicKey}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="secretId">Live Private Key</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Live Secret ID"
                                        type="text"
                                        name='livePrivateKey'
                                        value={state.livePrivateKey}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">{loader ? <>Connecting {props.name}<i className="fa fa-spinner fa-spin mx-1" /> </> : `Connect ${props.name}`}</button>
                </form>
            </Modal.Body >
        </Modal >
    )
}

export default IntegrationPopup