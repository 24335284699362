import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProductData } from '../../Actions/ProductAction'

const ProductPayment = ({ payment }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.product.data)

    const [paymentData, setPaymentData] = useState([])

    const handleChange = (e, curElem) => {
        const { checked } = e.target
        let newValue
        newValue = {
            ...curElem,
            isSelected: checked
        }

        let newArr = paymentData.map(function (value) {
            return value === curElem ? newValue : value;
        });

        setPaymentData(newArr)
        dispatch(addProductData("paymentProcess", newArr))
    }

    useEffect(() => {
        let arr = addNewProp(payment, state.paymentProcess)
        setPaymentData(arr)
    }, []);

    const addNewProp = (firstArray, secondArray) => {
        if (secondArray.length > 0) {
            return firstArray.map(obj => {
                const matchingObject = secondArray.find(secondObj => secondObj.name === obj.name);
                if (matchingObject) {
                    return { ...obj, isSelected: matchingObject.isSelected };
                }
                return obj;
            });
        } else {
            return firstArray.map(obj => {
                return { ...obj, isSelected: false };
            });
        }
    };

    return (
        <div className="col-lg-12">
            <div className="inpLabelWrap">
                <span className="labelTxt">Payment Processors</span>
                <div className='row'>
                    {
                        paymentData.map((curElem) => {
                            return (
                                <div className='col-lg-4'>
                                    <div className='payment-processors'>
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="d-flex align-items-center">
                                                <label className="custom-check alt">
                                                    <input
                                                        type="checkbox"
                                                        name={curElem.name}
                                                        checked={curElem.isSelected}
                                                        onChange={(e) => handleChange(e, curElem)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <span className='processorsName'>{curElem.name}</span>
                                            </div>
                                            {curElem.name === "stripe" ?
                                                <span className='processorsicon'><img src={require('../../images/Stripe.png')} /></span>
                                                :
                                                <span className='processorsicon'><img src={require(`../../images/${curElem.name}.png`)} /></span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductPayment