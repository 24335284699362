import axios from "axios";
import { commonAxios } from "../Global/CommonAxios";
import { baseURL } from "../Global/Global";
import { setAlert } from "./AlertAction";
import { fetchRebrandData } from "./RebrandAction";
import { fetchArticles, fetchVideos } from "./SupportAction";
import { fetchPaymentIntegration } from "./BusinessAction";

export const loadUser = () => (dispatch, getState) => {
  if (getState().auth.token) {
    commonAxios("fetch-user", {}, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: "LOAD_USER_DATA", payload: res.data });
          dispatch(fetchRebrandData(getState().auth.token));
          dispatch(fetchVideos());
          dispatch(fetchArticles());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const masterLoginUser = (user, setLoader, navigate) => (dispatch) => {
  commonAxios("master-login", user, dispatch)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "LOGIN_USER", payload: res.data });
        navigate("/dashboard");
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const loginUser = (data, setLoader) => (dispatch) => {
  localStorage.clear();
  commonAxios("login", data, dispatch)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        dispatch({ type: "LOGIN_USER", payload: res.data });
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const logoutUser = () => (dispatch, getState) => {
  commonAxios("logout", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        dispatch({ type: "LOGOUT" });
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgetPassword = (data, setLoader) => (dispatch) => {
  commonAxios("forgot-password", data, dispatch)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg.replace("if", "If"), "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const resetPassword = (data, setLoader, navigate) => (dispatch) => {
  commonAxios("reset-password", data, dispatch)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        navigate("/");
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const changeProfile =
  (data, setProfileStatus) => (dispatch, getState) => {
    commonAxios("update-profile-image", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          dispatch({ type: "CHANGE_USER_IMAGE", payload: res.data });
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setProfileStatus(false);
      })
      .catch((err) => {
        setProfileStatus(false);
      });
  };

export const updateName = (data, setLoader) => (dispatch, getState) => {
  commonAxios("profile", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        dispatch({ type: "CHANGE_USER_NAME", payload: data.name });
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const updatePassword =
  (data, setLoader, active) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          if (!active) {
            dispatch(logoutUser());
          }
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

export const changeEmail = (data, setLoader) => (dispatch, getState) => {
  commonAxios("change-email", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        dispatch({ type: "CHANGE_USER_EMAIL", payload: data.email });
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
};

export const gdprAction =
  (data, type, setShowDelete) => (dispatch, getState) => {
    axios({
      method: "POST",
      url: `${baseURL}${type}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: getState().auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          if (type === "download-user-data") {
            window.location.href = res.data.file;
          } else {
            dispatch(logoutUser());
          }

          dispatch(setAlert(res.data.message, "success"));
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);
      })
      .catch((error) => {
        dispatch(setAlert("Getting Internal Server Error", "danger"));
        setShowDelete(false);
      });
  };

export const currencyData = (data, setBaseCurrency) => (dispatch, getState) => {
  commonAxios("base-currency", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setBaseCurrency(res.data);
      } else {
        setBaseCurrency([]);
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const addCurrency =
  (data, handleClose3, setCurrencyLoader) => (dispatch, getState) => {
    commonAxios("currency-add",data, dispatch, getState().auth.token)
    .then((res) => {
      setCurrencyLoader(false);
      if (res.status === true) {
        
            handleClose3();
            dispatch(setAlert(res.msg,"success"))
            dispatch({ type: "SAVE_BASE_CURRENCY", payload: data });
            setCurrencyLoader(false)
       } else {
            dispatch(setAlert(res.msg,"danger"))
          }
          setCurrencyLoader(false)
    })
     .catch((err)=>{
      dispatch(setAlert(err.msg,"danger"))
      setCurrencyLoader(false)
    })
   
  };
