import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProductData } from '../../Actions/ProductAction'

const ExitIntent = () => {

    let dispatch = useDispatch();
    let existData = useSelector(state => state.product.data.existData)
    const templateArray = [
        {
            id: 1,
            imageSrc: "https://reelapps.s3.us-west-2.amazonaws.com/fast-paid/exit_intent/image_A.png"
        },
        {
            id: 2,
            imageSrc: "https://reelapps.s3.us-west-2.amazonaws.com/fast-paid/exit_intent/image_B.png"
        },
        {
            id: 3,
            imageSrc: "https://reelapps.s3.us-west-2.amazonaws.com/fast-paid/exit_intent/image_C.png"
        },
        {
            id: 4,
            imageSrc: "https://reelapps.s3.us-west-2.amazonaws.com/fast-paid/exit_intent/image_D.png"
        },
        {
            id: 5,
            imageSrc: "https://reelapps.s3.us-west-2.amazonaws.com/fast-paid/exit_intent/image_E.png"
        },
    ]


    const handleOnChange = (e) => {
        const { name, value } = e.target
        dispatch(addProductData("existData", { ...existData, [name]: value }))
    }

    const chooseTemplate = (ele) => {
        dispatch(addProductData("existData", { ...existData, templateId: ele.id }))
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Title</span>
                        <div className="inpLabel">
                            <label htmlFor="">Title</label>
                            <input
                                className="inpLabel-inp"
                                name='title'
                                placeholder="Add Title"
                                type="text"
                                value={existData.title}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className="inpLabelWrap">
                        <span className="labelTxt">Button</span>
                        <div className="inpLabel">
                            <label htmlFor="">Button</label>
                            <input
                                className="inpLabel-inp"
                                name="button"
                                placeholder="Continue"
                                type="text"
                                value={existData.button}
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="inpLabelWrap">
                    <span className="labelTxt">Body</span>
                    <div className="inpLabel">
                        <label htmlFor="">Body</label>
                        <textarea
                            type="text"
                            className="inpLabel-inp"
                            placeholder="Add A Description"
                            name="body"
                            value={existData.body}
                            onChange={(e) => handleOnChange(e)}
                        />
                    </div>
                </div>

            </div>

            <div className="tempList">
                <ul>
                    {
                        templateArray.length > 0 ?
                        templateArray.map((curElem, index) => {
                                return (
                                    <>
                                        {
                                            curElem.id === existData.templateId ?
                                                <li key={index} onClick={() => chooseTemplate(curElem)}>
                                                    <div className="tempList-single tempList-single_hov cursor-pointer">
                                                        <div className="tempList-img ">
                                                            <img src={curElem.imageSrc} alt="" />
                                                        </div>
                                                    </div>
                                                </li>
                                                : <li key={curElem} onClick={() => chooseTemplate(curElem)}>
                                                    <div className="tempList-single_hov cursor-pointer">
                                                        <div className="tempList-img ">
                                                            <img src={curElem.imageSrc} alt="" />
                                                        </div>
                                                    </div>
                                                </li>
                                        }
                                    </>
                                )
                            })
                            : ""
                    }
                </ul>
            </div>
        </>
    )
}

export default ExitIntent