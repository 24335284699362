import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const fetchRemainder =
  (data, setRemainder, setLoader) => (dispatch, getState) => {
    commonAxios("view-remainder", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setRemainder(res.data);
          setLoader(false);
          dispatch(setAlert(res.msg, "succes"));
        } else {
          setRemainder([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg));
        setLoader(false);
      });
  };

export const createRemainder =
  (data, setLoader, navigate, bid) => (dispatch, getState) => {
    commonAxios("create-remainder", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          setLoader(false);
          navigate(`/reminders?bid=${bid}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
      });
  };

// export const getProductData =
//   (data, setProductName) => (dispatch, getState) => {
//     commonAxios("view-customer", data, dispatch, getState().auth.token)
//       .then((res) => {
//         if (res.status) {
//           setProductName(res.data[0].productName);
//         } else {
//           dispatch(setAlert(res.msg, "danger"));
//         }
//       })
//       .catch((err) => {
//         dispatch(setAlert(err.msg, "danger"));
//       });
//   };

export const deleteReminder =
  (data, fetchRemainderData) => (dispatch, getState) => {
    commonAxios("delete-remainder", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          fetchRemainderData();
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const updateReminder =
  (data, setLoader, navigate, bid) => (dispatch, getState) => {
    commonAxios("update-remainder", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setLoader(false);
          dispatch(setAlert(res.msg, "success"));
          navigate(`/reminders?bid=${bid}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }setLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const viewEditRemainder =
  (data, setMainLoader, setRemainder) => (dispatch, getState) => {
    commonAxios("fetch-remainder", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setRemainder(res.data[0]);
          setMainLoader(false);
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setMainLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setMainLoader(false);
      });
  };

  export const sendReminder=(data)=>(dispatch,getState)=>{
    commonAxios("send-remainder",data,dispatch,getState().auth.token)
    .then((res)=>{
      if(res.status){
        dispatch(setAlert(res.msg,"success"))
      }
      else{
        dispatch(setAlert(res.msg,"danger"))
      }
    })
    .catch((err)=>{
      dispatch(setAlert(err.msg, "danger"));
    })
    

  }