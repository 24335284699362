import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import {
  createRemainder,
  viewEditRemainder,
  updateReminder,
} from "../../Actions/Remainder.Action";

import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { onFetchProducts } from "../../Actions/ProductAction";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { fetchBusinessCustomer } from "../../Actions/ClientAction";

const CreateReminders = () => {
  const location = useLocation();
  const bid = queryString.parse(location.search).bid;
  const id = queryString.parse(location.search).id;


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reminder, setReminder] = useState({
    name: "",
    pid: "",
    cid: "",
    type: "sms",
    data: "",
  });

  const [loader, setLoader] = useState(false);
  const [customer, setCustomer] = useState([]);
 const [mainLoader,setMainLoader] = useState(false)
  const [cid, setCid] = useState("");
  const [productName, setProductName] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "radio") {
      setReminder({
        ...reminder,
        type: value,
      });
    } else {
      setReminder({
        ...reminder,
        [name]: value,
      });
    }
  };

  const handleCreate = (e) => {
    e.preventDefault()
    if(id){
      setLoader(true);
    let data = { id: id, bid: bid, ...reminder };
    dispatch(updateReminder(data, setLoader, navigate, bid));
    }else{
      let data = { bid: bid, ...reminder };
    setLoader(true);
    dispatch(createRemainder(data, setLoader, navigate, bid));
    }
    
  };

  const fetchCustomerData = () => {
    let data = {};
    setLoader(true);
    dispatch(fetchBusinessCustomer(data, setCustomer, setLoader,true));
  };

  const fetchProductName = () => {
    let data = { bid: bid };
    dispatch(onFetchProducts(data, setProductName));
  };

  // const handleUpdateSave = () => {
  //   setLoader(true);
  //   let data = { id: id, bid: bid, ...reminder };
  //   dispatch(updateReminder(data, setLoader, navigate, bid));
  // };

  const handleEdit = () => {
    if (id) {
      setMainLoader(true);
      let data = {
        id: id,
      };
      dispatch(viewEditRemainder(data, setMainLoader, setReminder));
    }
  };

  useEffect(() => {
    handleEdit();
  }, [id]);

  useEffect(() => {
    fetchCustomerData();
    fetchProductName();

  }, []);

  return (
    <>
      {mainLoader? <div className="loader-sec">
          <div className="loader"></div>
        </div>:<><TitleBar title="Create Reminder" />
        <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
          <ViewBusinessNav active={"reminders"}/>
            <div className="business-top">
              <h2>{id?"Update Reminder":"Create Remainder"}</h2>
            </div>
            <form onSubmit={handleCreate}>
            <div className="business-list">
              <div className="inpLabelWrap mt-0">
                <span className="labelTxt">Name</span>
                <div className="inpLabel">
                  <label htmlFor="">Name <span  style={{
                                  float: "right",
                                  fontweight: "normal",
                                  marginRight: "10px",
                                }}>{reminder.name.length}/100</span></label>
                  <input
                    className="inpLabel-inp"
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={reminder.name}
                    onChange={(e) => handleChange(e)}
                    required
                    maxLength={100}
                  />
                </div>
              </div>

              <div className="inpLabelWrap">
                <span className="labelTxt">Customer</span>
                <div className="inpLabel">
                  <label htmlFor="">Customer</label>
                  <select
                    name="cid"
                    value={reminder.cid}
                    onChange={(e) => handleChange(e)}
                    className="inpLabel-inp"
                    required
                  >
                    <option value="">Customer Name</option>
                    {customer.length > 0
                      ? customer.map((curElem, index) => {
                          return (
                            <option value={curElem.id}>{curElem.name}</option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>

              <div className="inpLabelWrap">
                <span className="labelTxt">Product</span>
                <div className="inpLabel">
                  <label htmlFor="">Product Name</label>
                  <select
                    name="pid"
                    id=""
                    value={reminder.pid}
                    onChange={(e) => handleChange(e)}
                    className="inpLabel-inp"
                    required
                  >
                    <option value="">Product Name</option>
                    {productName.length > 0
                      ? productName.map((curElem, index) => {
                          return (
                            <option value={curElem.id}>{curElem.name}</option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>

              <div className="inpLabel d-flex justify-content-start">
                <label htmlFor="">Type</label>
                <div className="d-flex">
                  <div className="custom-radio">
                    {" "}
                    SMS
                    <input
                      type="radio"
                      value="sms"
                      name="radio"
                      checked={reminder.type === "sms" ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <span class="checkmark"></span>
                  </div>

                  <div className="custom-radio">
                    {" "}
                    email
                    <input
                      type="radio"
                      value="email"
                      name="radio"
                      checked={reminder.type === "email" ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <span class="checkmark"></span>
                  </div>
                </div>
              </div>

              <div className="inpLabelWrap">
                <span className="labelTxt">Content</span>
                <div className="inpLabel">
                  <label htmlFor="">Content</label>
                  <textarea
                    className="inpLabel-inp"
                    value={reminder.data}
                    name="data"
                    id=""
                    onChange={(e) => handleChange(e)}
                    required
                  ></textarea>
                </div>
              </div>

              {id ? (
                <div className="btn-sec text-right mt-4">
                  <button className="demoLink " type="submit">
                    {loader ? (
                      <>
                        Updating Remainder
                        <i
                          className="fa fa-spinner fa-spin mx-2"
                          style={{ fontSize: "15px" }}
                        />
                      </>
                    ) : (
                      "Update Remainder"
                    )}
                  </button>
                </div>
              ) : (
                <div className="btn-sec text-right mt-4">
                  <button className="demoLink" type="submit">
                    {loader ? (
                      <>
                        Creating Remainder
                        <i
                          className="fa fa-spinner fa-spin mx-2"
                          style={{ fontSize: "15px" }}
                        />
                      </>
                    ) : (
                      "Create Remainder"
                    )}
                  </button>
                </div>
              )}
            </div>
            </form>
          </div>
        </div>
      </section>
      <Footer /></>}
    </>
  );
};

export default CreateReminders;
