import React, { useEffect } from 'react'
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../images/modal-close.png";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { onUploadCSV } from '../../Actions/ClientAction';

const UploadCsvModal = ({ show, setShow, fetchCustomerData }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [msg, setMsg] = useState({
        error: "",
        name: ""
    });
    const [file, setFile] = useState("");
    const allowedExtensions = ["csv"];

    const handleClose = () => {
        setShow(false)
        setMsg({
            ...msg,
            error: "",
            name: ""
        });
        setFile("")
    }

    const onUploadFile = (e) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            console.log(inputFile)
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setMsg({
                    ...msg,
                    error: "Please input a csv file."
                });
                return;
            }
            setMsg({
                ...msg,
                name: inputFile.name
            })
            setFile(inputFile);
        }
    };

    const onUpload = () => {
        if (file !== "") {
            setLoader(true)
            const formData = new FormData
            formData.append("file", file)
            formData.append("type", "text/csv")
            dispatch(onUploadCSV(formData, fetchCustomerData, handleClose, setLoader))
        }
    }

    useEffect(() => {
        if (msg.error !== "") {
            setTimeout(() => {
                setMsg({
                    ...msg,
                    error: "",
                    name: ""
                })
            }, 5000)
        }
    }, [msg])


    return (
        <Modal
            className="VideoModal small"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img src={modalCloseIcon} alt="" />
                </div>
                <form className="formSec">
                    <div className="inpField">
                        <label className="text-center pb-0"> Upload the CSV file</label>
                        <hr className="text-dark" />
                        <div className="col-lg-12 p-0">
                            <h6 className="text-dark" style={{ fontSize: "13px" }}>
                                Click here to download the format
                            </h6>

                            <div className="inpLabel" style={{ marginTop: "-14px" }}>
                                <div className="inpLabel">
                                    <div className="col-12 p-0">
                                        <div className="style-upload-box">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                onChange={(e) => onUploadFile(e)}
                                            />
                                            <div>
                                                {loader.uploadLoader ? (
                                                    <>
                                                        <i
                                                            className="fa fa-spinner fa-spin mr-2"
                                                            style={{ fontSize: "30px" }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <AiOutlineCloudUpload />
                                                        <h6>{msg.name !== "" ? msg.name : "Upload your CSV file"} </h6>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <span className='text-danger'>{msg.error}</span>

                                    </div>
                                    <div className="col-12 d-flex justify-content-end  mt-2">
                                        <div className="csv_button_wrapper demoLink" onClick={onUpload} style={{ background: file !== "" ? "" : "gray" }}>
                                            {loader ? (
                                                <>
                                                    Uploading
                                                    <i
                                                        className="fa fa-spinner fa-spin mx-1"
                                                    />
                                                </>
                                            ) : (
                                                "Upload"
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UploadCsvModal