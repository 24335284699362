import React from 'react'
import { useDispatch } from 'react-redux'
import { onProductAddMeridiemFrom, onProductAddMeridiemTo, onProductAddTimeFrom, onProductAddTimeTo, onEnableProductDay } from '../../Actions/ProductAction'

const OpeningHours = ({ curElem, index, format }) => {
    const dispatch = useDispatch()

    const handleTime = (e, isFrom, isTo) => {
        const { checked, name, value } = e.target
        if (name === "enable") {
            dispatch(onEnableProductDay(index, checked))
        }
        else if (name === "meridiem") {
            if (isFrom) {
                dispatch(onProductAddMeridiemFrom(index, value))
            }
            if (isTo) {
                dispatch(onProductAddMeridiemTo(index, value))
            }
        }
        else {
            if (isFrom) {
                dispatch(onProductAddTimeFrom(index, value))
            }
            if (isTo) {
                dispatch(onProductAddTimeTo(index, value))
            }
        }
    }

    return (
        <div className="openHr-single">
            <div className="openHr-single-left">
                <div>{curElem.day}</div>
                <div>
                    <label className="switch flex">
                        <input
                            type="checkbox"
                            name="enable"
                            checked={curElem.enable}
                            onChange={(e) => handleTime(e, index)}
                        />
                        <span className="slider round"></span>
                        <span className="slClose">Closed</span>
                        <span className="slOpen">Open</span>
                    </label>
                </div>
            </div>
            {curElem.enable ?
                <div className="openHr-single-right">
                    <div className="timeFrom">
                        <div>FROM</div>
                        {format !== "24" ? (
                            <>
                                <div
                                    className="timeFrom-box"
                                >
                                    <select
                                        name="from"
                                        value={curElem.fromTime}
                                        onChange={(e) => handleTime(e, true, false)}
                                        style={{ width: "45%" }}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="1:00">1:00</option>
                                        <option value="2:00">2:00</option>
                                        <option value="3:00">3:00</option>
                                        <option value="4:00">4:00</option>
                                        <option value="5:00">5:00</option>
                                        <option value="6:00">6:00</option>
                                        <option value="7:00">7:00</option>
                                        <option value="8:00">8:00</option>
                                        <option value="9:00">9:00</option>
                                        <option value="10:00">10:00</option>
                                        <option value="11:00">11:00</option>
                                        <option value="12:00">12:00</option>
                                    </select>

                                    <select
                                        name="meridiem"
                                        value={curElem.fromMeridiem}
                                        onChange={(e) => handleTime(e, true, false)}
                                        style={{ width: "60%" }}
                                    >
                                        <option value="">Select Meridiem</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </div>
                            </>)

                            : (
                                <>

                                    <div
                                        className="timeFrom-box"
                                    >
                                        <select
                                            style={{ width: "100%" }}
                                            name="from"
                                            value={curElem.fromTime}
                                            onChange={(e) => handleTime(e, true, false)}
                                        >
                                            <option value="">Select Time</option>
                                            <option value="1:00">1:00</option>
                                            <option value="2:00">2:00</option>
                                            <option value="3:00">3:00</option>
                                            <option value="4:00">4:00</option>
                                            <option value="5:00">5:00</option>
                                            <option value="6:00">6:00</option>
                                            <option value="7:00">7:00</option>
                                            <option value="8:00">8:00</option>
                                            <option value="9:00">9:00</option>
                                            <option value="10:00">10:00</option>
                                            <option value="11:00">11:00</option>
                                            <option value="12:00">12:00</option>
                                            <option value="13:00">13:00</option>
                                            <option value="14:00">14:00</option>
                                            <option value="15:00">15:00</option>
                                            <option value="16:00">16:00</option>
                                            <option value="17:00">17:00</option>
                                            <option value="18:00">18:00</option>
                                            <option value="19:00">19:00</option>
                                            <option value="20:00">20:00</option>
                                            <option value="21:00">21:00</option>
                                            <option value="22:00">22:00</option>
                                            <option value="23:00">23:00</option>
                                            <option value="24:00">24:00</option>

                                        </select>
                                    </div>
                                </>)
                        }



                    </div>
                    <div className="timeFrom">
                        <div>TO</div>
                        {format !== "24" ? (
                            <>

                                <div
                                    className="timeFrom-box"
                                >
                                    <select
                                        name="to"
                                        value={curElem.toTime}
                                        onChange={(e) => handleTime(e, false, true)}
                                        style={{ width: "45%" }}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="1:00">1:00</option>
                                        <option value="2:00">2:00</option>
                                        <option value="3:00">3:00</option>
                                        <option value="4:00">4:00</option>
                                        <option value="5:00">5:00</option>
                                        <option value="6:00">6:00</option>
                                        <option value="7:00">7:00</option>
                                        <option value="8:00">8:00</option>
                                        <option value="9:00">9:00</option>
                                        <option value="10:00">10:00</option>
                                        <option value="11:00">11:00</option>
                                        <option value="12:00">12:00</option>
                                    </select>
                                    <select
                                        name="meridiem"
                                        value={curElem.toMeridiem}
                                        onChange={(e) => handleTime(e, false, true)}
                                        style={{ width: "60%" }}
                                    >
                                        <option value="">Select Meridiem</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </select>
                                </div>
                            </>)


                            : (
                                <>

                                    <div
                                        className="timeFrom-box"
                                    >
                                        <select
                                            name="to"
                                            style={{ width: "100%" }}
                                            value={curElem.toTime}
                                            onChange={(e) => handleTime(e, false, true)}
                                        >
                                            <option value="">Select Time</option>
                                            <option value="1:00">1:00</option>
                                            <option value="2:00">2:00</option>
                                            <option value="3:00">3:00</option>
                                            <option value="4:00">4:00</option>
                                            <option value="5:00">5:00</option>
                                            <option value="6:00">6:00</option>
                                            <option value="7:00">7:00</option>
                                            <option value="8:00">8:00</option>
                                            <option value="9:00">9:00</option>
                                            <option value="10:00">10:00</option>
                                            <option value="11:00">11:00</option>
                                            <option value="12:00">12:00</option>
                                            <option value="13:00">13:00</option>
                                            <option value="14:00">14:00</option>
                                            <option value="15:00">15:00</option>
                                            <option value="16:00">16:00</option>
                                            <option value="17:00">17:00</option>
                                            <option value="18:00">18:00</option>
                                            <option value="19:00">19:00</option>
                                            <option value="20:00">20:00</option>
                                            <option value="21:00">21:00</option>
                                            <option value="22:00">22:00</option>
                                            <option value="23:00">23:00</option>
                                            <option value="24:00">24:00</option>

                                        </select>
                                    </div>
                                </>)
                        }
                    </div>
                </div > : ""
            }
        </div >
    )
}

export default OpeningHours