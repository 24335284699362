import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import BusinessGeneral from "../CreateBusiness/BusinessGeneral";
import BusinessDesign from "../CreateBusiness/BusinessDesign";
import BusinessIntregration from "../CreateBusiness/BusinessIntregration";
import { Nav, Tab } from 'react-bootstrap';
import ViewBusinessNav from "./ViewBusinessNav";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string'
import { useDispatch } from "react-redux";
import { fetchPaymentIntegration, onGetBusiness } from "../../Actions/BusinessAction";

const ViewBusiness = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const bid = queryString.parse(location.search).bid;
    const design = queryString.parse(location.search).design;

    let [loader, setLoader] = useState({
        mainLoader: false
    });

    const fetchBusiness = () => {
        setLoader({
            ...loader,
            mainLoader: true
        })
        let data = {
            id: bid
        }
        dispatch(onGetBusiness(data, navigate, setLoader, loader))
        delete data.id
        data.bid = bid
        dispatch(fetchPaymentIntegration(data));
    }


    useEffect(() => {
        if (bid) {
            fetchBusiness()
        }
    }, [bid])

    return (
        <>
            <TitleBar title="Create Business" />
            <Navbar activeNav={"business"} />
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">
                        <ViewBusinessNav active={"business"} />
                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={design === "true" ? "tab2" : "tab1"}>
                                <Nav className="navSet nav-fill">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">General</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Design</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">Integration</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="productTabs">
                                    <Tab.Pane eventKey="tab1">
                                        <BusinessGeneral />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <BusinessDesign mainLoader={loader.mainLoader} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <BusinessIntregration />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ViewBusiness;