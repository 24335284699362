import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { fetchBusinessCustomer } from "../../Actions/ClientAction";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import ClientRows from "./ClientRows";
import UploadCsvModal from "./UploadCsvModal";
import { useSelector } from "react-redux";
import { setAlert } from "../../Actions/AlertAction";

const Clients = () => {
  const location = useLocation();
  const id = queryString.parse(location.search).bid;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const symbol = auth.user.symbol;

  const [customer, setCustomer] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [pageNumber, setPageNumber] = useState([]);
  const [state, setState] = useState({
    name: false,
    pName: false,
    price: false,
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleSort = (type) => {
    let data = [...customerData];
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    } else if (type === "pName") {
      if (state.pName) {
        data.sort((a, b) => {
          if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          pName: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          pName: true,
        });
      }
    } else if (type === "price") {
      if (state.price) {
        data.sort((a, b) => {
          if (a.price.toLowerCase() > b.price.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          pName: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.price.toLowerCase() < b.price.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          price: true,
        });
      }
    }
    setCustomerData(data);
  };

  const fetchCustomerData = () => {
    let data = {};
    setCustomer([]);
    setLoader(true);
    if (id) {
      data = { bid: id };
    }
    dispatch(fetchBusinessCustomer(data, setCustomer, setLoader));
  };

  useEffect(() => {
    fetchCustomerData();
  }, [id]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "1") {
      setMode("Paid");
    } else if (value === "0") {
      setMode("Non Paid");
    }
  };

  useEffect(() => {
    if (search === "" && mode === "") {
      setCustomerData(customer);
    } else {
      const val = customer
        .filter((curElem) => {
          if (mode === "") {
            return curElem;
          } else {
            let data = curElem.paymentStatus === mode;
            return data;
          }
        })
        .filter((curElem) => {
          let data = curElem.name.toLowerCase().includes(search.toLowerCase());
          if (data) {
            return curElem;
          }
        });
      setCustomerData(val);
    }
  }, [mode, search, customer]);

  const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexOfFirstTodo = indexofLastTodo - pagination.totalItemonPage;
  const currentTodo =
    (search === "" && mode !== "") ||
      (search !== "" && mode === "") ||
      (search !== "" && mode !== "")
      ? customerData
      : customerData.slice(indexOfFirstTodo, indexofLastTodo);

  useEffect(() => {
    if (customerData.length > 0) {
      let maxLength = customerData.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [customerData]);

  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };
  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };

  const onExportCustomer = () => {
    if (customer.length > 0) {
      let rows = []
      if (id) {
        rows = [
          ["Name", "Product", "Price", "Paid By", "Created"],
        ];
        customer.forEach((curElem) => {
          let price = curElem.productinfo[0] ? curElem.productinfo[0].price : "";
          let arr = [
            curElem.name,
            curElem.productName,
            price,
            curElem.paidby,
            curElem.created,
          ];
          rows.push(arr);
        });
      }
      else {
        rows = [
          ["Name", "Email", "Phone", "Created"],
        ];
        customer.forEach((curElem) => {
          let arr = [
            curElem.name,
            curElem.email,
            curElem.isPhone,
            curElem.created,
          ];
          rows.push(arr);
        });
      }

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    } else {
      dispatch(
        setAlert(
          "!You do not have customers for this business to export.",
          "warning"
        )
      );
    }
  };

  return (
    <>
      <TitleBar title="Customer" />
      {id ? <Navbar activeNav={"business"} /> : <Navbar activeNav={"customer"} />}
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            {id ? <ViewBusinessNav /> : ""}

            <div className="business-top">
              <h2>Your Customers</h2>
              {id ? (
                ""
              ) : (
                <Link
                  to={id ? `/create-clients?bid=${id}` : "/create-clients"}
                  className="demoLink mt-0 "
                >
                  Create Customer <FiArrowRight />
                </Link>
              )}
            </div>
            <div className="business-list">
              <div className="business-search justify-content-between">
                <div className="business-search-left">
                  <div className="business-search-inp">
                    <span className="searchIcon">
                      <FiSearch />
                    </span>
                    <input
                      className="inpSearch withIcon"
                      type="text"
                      onChange={(e) => handleSearch(e)}
                      placeholder="Search customer name"
                    />
                  </div>
                  <div className="business-search-inp ml-2">
                    {id ? (
                      <select
                        name=""
                        id=""
                        onChange={(e) => handleSelect(e)}
                        className="inpSearch"
                      >
                        <option value={""}>All</option>
                        <option value={1}>Paid</option>
                        <option value={0}>Non-Paid</option>
                      </select>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="business-search-right">
                  {id ? (
                    ""
                  ) : (
                    <span className="demoLink mt-0" onClick={handleShow}>
                      Bulk import Customer
                    </span>
                  )}
                  {currentTodo.length > 0 ? <span
                    className="demoLink mt-0 ml-2"
                    onClick={onExportCustomer}
                  >
                    Export Customer
                  </span> : ""}
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <thead>
                    <tr>
                      {/* <th width="120"></th> */}
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSort("name")}
                      >
                        Name
                      </th>
                      {id ? (
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSort("pName")}
                        >
                          Product
                        </th>
                      ) : (
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSort("pName")}
                        >
                          Email
                        </th>
                      )}
                      {id ? (
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSort("price")}
                        >
                          Price ({symbol})
                        </th>
                      ) : (
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSort("price")}
                        >
                          Phone No.
                        </th>
                      )}
                      <th className="cursor-pointer">{id ? "Paid By" : ""}</th>
                      <th className="cursor-pointer">
                        {id ? "Payment Status" : ""}
                      </th>
                      <th className="cursor-pointer">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTodo.length > 0
                      ? currentTodo.map((curElem, index) => {
                        return (
                          <React.Fragment key={index}>
                            <ClientRows
                              bid={id}
                              id={curElem.id}
                              name={curElem.name}
                              productName={curElem.productName}
                              email={curElem.email}
                              phone={curElem.isPhone}
                              price={
                                curElem.productinfo.length > 0
                                  ? curElem.productinfo[0].price
                                  : ""
                              }
                              paidBy={curElem.paidby}
                              paymentStatus={curElem.paymentStatus}
                              fetchCust={fetchCustomerData}
                            />
                          </React.Fragment>
                        );
                      })
                      : ""}
                  </tbody>
                </table>

                {loader === true ? (
                  ""
                ) : customer.length === 0 ? (
                  ""
                ) : currentTodo.length !== 0 ? (
                  ""
                ) : (
                  <div className="text-center mt-4 text-capitalize" >
                    "No Data Found"
                  </div>
                )}

                {customer.length === 0 ? (
                  loader ? (
                    <div
                      className="text-center"
                      style={{ fontSize: "xx-large" }}
                    >
                      <i className="fa fa-spinner fa-spin mx-4" />
                    </div>
                  ) : (
                    <div className="text-center mt-4 ">
                      <p style={{ color: "#2552af" }}>
                        "You have not created any customer yet"
                      </p>
                    </div>
                  )
                ) : (
                  ""
                )}

                <div className="tablepage">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft
                          style={{
                            color: pageNumber.length <= 1 ? "gray" : "white",
                          }}
                        />
                      </a>
                    </li>
                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                        return (
                          <li
                            key={index}
                            id={curElem}
                            style={{ color: "white" }}
                            onClick={() => handleClick(curElem)}
                            className="cursor-pointer"
                          >
                            <a
                              style={
                                curElem === pagination.currentPage
                                  ? {
                                    backgroundColor: "white",
                                    color: "#400C46",
                                  }
                                  : {}
                              }
                            >
                              {curElem}
                            </a>
                          </li>
                        );
                      })
                      : ""}

                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight
                          style={{
                            color: pageNumber.length <= 1 ? "gray" : "white",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UploadCsvModal
        show={show}
        setShow={setShow}
        fetchCustomerData={fetchCustomerData}
      />
      <Footer />
    </>
  );
};

export default Clients;
