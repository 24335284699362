import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { listStaffData, deleteStaff } from "../../Actions/StaffAction";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import Swal from "sweetalert2";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { FiArrowRight, FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Staff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [staff, setStaff] = useState([]);
  const [loader, setLoader] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [mode, setMode] = useState("");
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [pageNumber, setPageNumber] = useState([]);
  const [state, setState] = useState({
    name: false,
    createdDate: false,
  });

  const fetchStaff = () => {
    let data = {};
    setLoader(true);
    dispatch(listStaffData(data, setStaff, setLoader));
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const handlsort = (type) => {
    let data = [...staff];
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    } else if (type === "created") {
      if (state.createdDate) {
        data.sort((a, b) => {
          if (a.created.toLowerCase() < b.created.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          createdDate: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.created.toLowerCase() > b.created.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          createdDate: true,
        });
      }
    }
    setStaff(data);
  };

  const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexofFirsTodo = indexofLastTodo - pagination.totalItemonPage;
  const currentTodo =
    (search === "" && mode !== "") ||
      (search !== "" && mode === "") ||
      (search !== "" && mode !== "")
      ? staffData
      : staffData.slice(indexofFirsTodo, indexofLastTodo);

  useEffect(() => {
    if (staffData.length > 0) {
      let maxLength = staffData.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [staffData]);

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };
  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };

  //Search bar and Filter
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "0") {
      setMode("0");
    } else if (value === "1") {
      setMode("1");
    }
  };

  useEffect(() => {
    if (search === "" && mode === "") {
      setStaffData(staff);
    } else {
      const val = staff
        .filter((curElem) => {
          if (mode === "") {
            return curElem;
          } else {
            let data = curElem.status === mode;
            return data;
          }
        })
        .filter((curElem) => {
          let data = curElem.name.toLowerCase().includes(search.toLowerCase());
          if (data) {
            return curElem;
          }
        });
      setStaffData(val);
    }
  }, [staff, mode, search]);

  const handleEdit = (id) => {
    navigate(`/create-staff?staffId=${id}`);
  };

  const handleDelete = (id) => {
    let data = { id: id };
    setLoader(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        dispatch(deleteStaff(data, setLoader, fetchStaff));
      }
    });
  };

  return (
    <>
      <TitleBar title="Staff" />
      <Navbar activeNav={"staff"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <div className="business-top">
              <h2>Your Staffs</h2>
              <Link className="demoLink mt-0" to="/create-staff">
                Create Staff <FiArrowRight />
              </Link>
            </div>
            <div className="business-list">
              <div className="business-search">
                <div className="business-search-inp">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    className="inpSearch withIcon"
                    type="text"
                    placeholder="Search Staff name"
                    onChange={handleSearch}
                  />
                </div>
                <div className="business-search-inp ml-2">
                  <select
                    name=""
                    id=""
                    onChange={(e) => handleSelect(e)}
                    className="inpSearch"
                  >
                    <option value={""}>All</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <tr>
                    {/* <th width="150"></th> */}
                    <th
                      onClick={() => handlsort("name")}
                      className="cursor-pointer ml-2"
                    >
                      Name
                    </th>
                    <th>Email</th>
                    {/* <th>Staff ID</th> */}
                    <th>Status</th>
                    <th
                      onClick={() => handlsort("created")}
                      className="cursor-pointer"
                    >
                      Created
                    </th>
                    <th>Action</th>
                  </tr>
                  {currentTodo.length > 0
                    ? currentTodo.map((curElem, index) => {
                      return (
                        <tr>
                          {/* <td>
                              <div className="proThumb">
                                <img src={curElem.image} alt="" />
                              </div>
                            </td> */}
                          <td>{curElem.name}</td>
                          <td>{curElem.email}</td>
                          {/* <td>{curElem.id}</td> */}

                          <td>
                            <span className={curElem.status === "1" ? "live pMode": "sandbox pMode"}>
                              {curElem.status === "1" ? "Active" : "Inactive"}
                            </span>
                          </td>
                          <td>{curElem.created.split(" ", 1)}</td>
                          <td>
                            <a className="p-0">
                              <FiEdit
                                className="smIcon cursor-pointer"
                                onClick={() => handleEdit(curElem.id)}
                                title="Edit"
                              />
                            </a>
                            <a>
                              <FiTrash2
                                className="smIcon cursor-pointer "
                                onClick={() => handleDelete(curElem.id)}
                                title="Delete"
                              />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                    : ""}
                </table>
                {loader === true ? (
                  ""
                ) : staff.length === 0 ? (
                  ""
                ) : currentTodo.length !== 0 ? (
                  ""
                ) : (
                  <div className="text-center mt-4 text-capitalize" >
                    "No Data Found"
                  </div>
                )}

                {staff.length === 0 ? (
                  loader ? (
                    <div
                      className="text-center"
                      style={{ fontSize: "xx-large" }}
                    >
                      <i className="fa fa-spinner fa-spin mx-4" />
                    </div>
                  ) : (
                    <div className="text-center" >
                      <p style={{ color: "#2552af" }}>
                        "You have not created any staff yet"
                      </p>
                    </div>
                  )
                ) : (
                  ""
                )}
                <div className="tablepage">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft style={{ color: pageNumber.length<=1?"gray":"white" }} />
                      </a>
                    </li>

                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                        return (
                          <li
                            key={index}
                            id={curElem}
                            style={{ color: "white" }}
                            onClick={() => handleClick(curElem)}
                            className="cursor-pointer"
                          >
                            <a
                              style={
                                curElem === pagination.currentPage
                                  ? {
                                    backgroundColor: "white",
                                    color: "#400C46",
                                  }
                                  : {}
                              }
                            >
                              {curElem}
                            </a>
                          </li>
                        );
                      })
                      : ""}
                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight style={{ color: pageNumber.length<=1?"gray":"white"}} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Staff;
