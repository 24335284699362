import React, { useState } from "react";
import { useDispatch } from "react-redux";
import IntegrationPopup from "./IntegrationPopup";
import TwilioPopup from "./TwilioPopup";
import Swal from "sweetalert2";
import { onConnectAutoRes, onDeleteIntegration } from "../../Actions/BusinessAction";
import { useLocation } from "react-router";
import queryString from "query-string";
import { randomstring } from 'randomstring-js'
import AutoResponderPopup from "./AutoResponderPopup";



const ConnectReelapps = (props) => {
    const dispatch = useDispatch();
    let location = useLocation()
    const bId = queryString.parse(location.search).bid
    const [showModal, setShowModal] = useState(false);
    const [checkStatus, setCheckStatus] = useState(true);
    const [loader, setLoader] = useState(false)
    const [randomString, setRandomString] = useState(randomstring())

    const handleShow = () => setShowModal(true)
    const handleClose = () => setShowModal(false)

    const [modal2,setModal2] = useState(false)
    const handleTwilio=()=>setModal2(true)
    const handleClose2=()=>setModal2(false)

    // const[modal3,setModal3] = useState(false)
    // const handleWebinar=()=>setModal3(true)
    // const handleClose3=()=>setModal3(false) 

    const [showAuto, setShowAuto] = useState(false)
    const handleShow1 = () => setShowAuto(true)
    const handleClose1 = () => setShowAuto(false)

    const deleteIntegration = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: `Do you want to disconnect this ${props.type} account!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, disconnect it!",
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    id: id,
                    type: props.type
                };
                dispatch(onDeleteIntegration(data, bId));
                Swal.fire({
                    title: "Are you sure?",
                    text: `Do you want to disconnect this ${props.type} account!`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Disconnecting...",
                    timer: 1000,
                });
            }
        });
    };


    const handleShowAutoRespond = () => {
        if (props.type === "mv" || props.type === "si") {
            handleShow1()
        } else {
            let data = {
                type: props.type,
                bid: bId,
                key: randomString
            }
            setLoader(true)
            dispatch(onConnectAutoRes(data, checkStatus, setCheckStatus, randomString, setLoader))
        }
    }


    return (
        <>
            <div className="connection-box mt-3" id={props.type}>
                <div className="connection-box-main">
                    <div className={`connection-img ${props.data !== undefined && props.data.length >0 ? '' : 'disable-img'}`}>
                        <img src={props.icon} width="56px" alt={props.name} />
                    </div>
                    <div className="connection-txt">
                        {props.data !== undefined &&
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connection text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>{props.name} {item?.email}</h6>
                                                    <p className="m-0">{item.data.user_name} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button onClick={(e) => deleteIntegration(item.id)} type="button" className="btn-change7">Disconnect</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                {props.activeFor === "payment" ?
                                                    <button onClick={handleShow} className="btn-change7">Connect New Account</button>
                                                    :
                                                    props.activeFor==="twilio"?<button onClick={handleTwilio} className="btn-change7 ">Connect</button>:
                                                    <button onClick={handleShowAutoRespond} className="btn-change7">{loader ? <>Connecting <i className="fa fa-spinner fa-spin" /></> : "Connect New Account"}</button>

                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white">
                                        <div className="youtube-content">
                                            <h6>{props.name}</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account ">
                                        {props.activeFor === "payment" ?
                                            <button onClick={handleShow} className="btn-change7 ">Connect</button>
                                            :
                                            props.activeFor==="twilio"?<button onClick={handleTwilio} className="btn-change7 ">Connect</button>:
                                            <button onClick={handleShowAutoRespond} className="btn-change7 d-flex align-items-center">{loader ? <>Connecting <i className="fa fa-spinner fa-spin ms-2" /></> : "Connect"}</button>

                                        }
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>

            <IntegrationPopup
                show={showModal}
                handleClose={handleClose}
                name={props.name}
            />

            {/* <WebinarPopup
            show={modal3}
            handleClose3={handleClose3}
            name={props.name}
            bId={bId}
            type={props.type}
            /> */}

            <TwilioPopup
            show={modal2}
            handleClose2={handleClose2}
            name={props.name}
            bId={bId}
            type={props.type}
            />

            <AutoResponderPopup
                show={showAuto}
                handleClose={handleClose1}
                name={props.name}
                bId={bId}
                type={props.type}
            />
        </>
    )
}

export default ConnectReelapps;