import produce from "immer"
const initialState = {
    data: {
        name: "",
        description: "",
        address: "",
        phone: "",
        email: "",
        timezone: "",
        timeFormat: "24",
        localDefault: true,
        businessUrl: "",
        mode: "sandbox",
        socialMedia: {
            isSocialMedia: false,
            facebook: "",
            youtube: "",
            instagram: "",
            twitter: ""
        },
        openingHour: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Monday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Tuesday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Wednesday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Thursday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Friday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            },
            {
                day: "Saturday",
                enable: false,
                fromTime: "9:00",
                fromMeridiem: "",
                toTime: "21:00",
                toMeridiem: ""
            }
        ],
        domain: "",
        logo: "",
        design: {
            background: "#ffff",
            cover: "#rgb(164 219 227)",
            logoEnable: true,
            callEnable: true,
            emailEnable: true,
            nameEnable: true,
            aboutEnable: true,
            descriptionEnable: true,
            socialMediaEnable: false,
            primaryColor: "#1755d4",
            secondColor: "#103076",
            fontColor: "#000000"
        }
    },
    integration: {
        paypal: [],
        stripe: [],
        razorPay: [],
        payU: [],
        getResponse: [],
        mailchip: [],
        aweber: [],
        sendiio: [],
        sendlane: [],
        sendgrid: [],
        mailvio: [],
        zoom: [],
        gotoWebinar: [],
        twilio:[]
    }
}

export const BusinessReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TIME_FORMATE":
            return produce(state, (draft) => {
                draft.data.timeFormat = action.payload
            })

        case "ADD_FORMATE_DATA":
            return produce(state, (draft) => {
                draft.data.openingHour = action.payload
            })

        case "ADD_LOCAL_DEFAULT":
            return produce(state, (draft) => {
                draft.data.localDefault = action.payload
            })

        case "ADD_BUSINESS_DATA":
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.name]: action.payload.value
                }
            }

        case "ADD_DESIGN_DATA":
            return produce(state, (draft) => {
                draft.data.design = action.payload
            })

        case "ADD_BUSINESS_LOGO":
            return produce(state, (draft) => {
                draft.data.logo = action.payload
            })

        case "ADD_BUSINESS_ALL":
            return produce(state, (draft) => {
                draft.openingHour = action.payload
            })

        case "ON_ENABLE_DAY":
            return produce(state, (draft) => {
                draft.data.openingHour[action.payload.index].enable = action.payload.checked
            })

        case "ADD_FROM_MERIDIEM":
            return produce(state, (draft) => {
                draft.data.openingHour[action.payload.index].fromMeridiem = action.payload.value
            })
        case "ADD_TO_MERIDIEM":
            return produce(state, (draft) => {
                draft.data.openingHour[action.payload.index].toMeridiem = action.payload.value
            })

        case "ADD_FROM_TIME":
            return produce(state, (draft) => {
                draft.data.openingHour[action.payload.index].fromTime = action.payload.value
            })

        case "ADD_TO_TIME":
            return produce(state, (draft) => {
                draft.data.openingHour[action.payload.index].toTime = action.payload.value
            })

        case "SET_BUSINESS":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "UNMOUNT_BUSINESS":
            return initialState


        //Integration
        case "ADD_PAYMENT_CONNECTION":
            return produce(state, (draft) => {
                draft.integration.paypal = action.payload.paypal
                draft.integration.stripe = action.payload.stripe
                draft.integration.razorPay = action.payload.razorpay
                draft.integration.payU = action.payload.payU
                draft.integration.getResponse = action.payload.getresponse
                draft.integration.mailchip = action.payload.mailchip
                draft.integration.aweber = action.payload.aweber
                draft.integration.sendiio = action.payload.sendiio
                draft.integration.sendlane = action.payload.Sendlane
                draft.integration.sendgrid = action.payload.Sendgrid
                draft.integration.mailvio = action.payload.Mailvio
                draft.integration.twilio = action.payload.twillio
                draft.integration.zoom = action.payload.zoom
            })

        default:
            return state
    }

}