import axios from "axios"

export const fetchVideos = () => async (dispatch) => {
    await axios({
        method: "POST",
        url: "https://adminserver.reelapps.io/api/fetch-videos",
        data: JSON.stringify({ app_id: 43 }),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_VIDEOS', payload: res.data.data })
        }
    }).catch(err => {
        console.log(err)
    })

}

export const fetchArticles = () => async (dispatch) => {
   await  axios({
        method: "POST",
        url: "https://adminserver.reelapps.io/api/fetch-article",
        data: JSON.stringify({ app_id: 43 }),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_ARTICLES', payload: res.data.data })
        }
    }).catch(err => {
        console.log(err)
    })

}