import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddResellerAccount from "./AddResellerAccount";
import TitleBar from "../TitleBar";

const Reseller = () => {
    return(
        <>
            <TitleBar title="Reseller"/>
            <Navbar/>

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                               <AddResellerAccount/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Reseller;