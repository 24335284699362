import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation} from "react-router-dom";
import queryString from "query-string";
import { NavLink } from 'react-router-dom'
import { useEffect } from 'react';
import { onGetBusiness } from '../../Actions/BusinessAction';

const ViewBusinessNav = ({ active }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const bid = queryString.parse(location.search).bid

    const fetchBusiness = () => {
        let data = {
          id: bid,
        };
        dispatch(onGetBusiness(data));
      };

    useEffect(()=>{
        fetchBusiness();
    },[])
    return (
        <div className="productNav nav mb-4">
            <div className="nav-item"><NavLink className={`nav-link ${active === "business" ? "active" : ""}`} to={`/view-business?bid=${bid}`}>Business</NavLink></div>
            <div className="nav-item"><NavLink className={`nav-link ${active === "product" ? "active" : ""}`} to={`/product?bid=${bid}`}>Products</NavLink></div>
            <div className="nav-item"><NavLink className={`nav-link ${active === "analytics" ? "active" : ""}`} to={`/business-analytics?bid=${bid}`}>Analytics</NavLink></div>
            <div className="nav-item"><NavLink className={`nav-link ${active === "customer" ? "active" : ""}`} to={`/business-customer?bid=${bid}`}>Customers</NavLink></div>
            <div className="nav-item"><NavLink className={`nav-link ${active === "coupons" ? "active" : ""}`} to={`/coupon?bid=${bid}`}>Coupons</NavLink></div>
            <div className="nav-item"><NavLink className={`nav-link ${active === "reminders" ? "active" : ""}`} to={`/reminders?bid=${bid}`}>Reminders</NavLink></div>
        </div>
    )
}

export default ViewBusinessNav