import React, { useState } from 'react'
import { BsCodeSlash, BsShare } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { SiMaterialdesignicons } from 'react-icons/si'
import { useDispatch } from 'react-redux'
import ModalPopup from '../Business/ModalPopup'
import { onDuplicateBusiness } from "../../Actions/BusinessAction";
import { Link } from "react-router-dom";


const BusinessDropdown = ({ curElem, compoType, fetchBusiness }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [type, setType] = useState("")
    const [embed, setEmbed] = useState({
        header: '',
        headerStatus: false,
        checkout: '',
        checkoutStatus: false,
    })

    const handleShow = (val) => {
        setType(val)
        setEmbed({
            ...embed,
            header: `<script type="text/Javascript" id="fe_widget" src="https://backend.bizbookpay.com/front-end/widget.js?bid=${curElem.id}"></script>`,
            checkout: `<div id="fastpaid_checkout_section"></div>`
        })
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    const handleDuplicate = (e) => {
        let data = {
            id: curElem.id
        }
        dispatch(onDuplicateBusiness(data, fetchBusiness))
    }
    return (
        <>
            <span onClick={() => handleShow("copy")}><BsCodeSlash /> Embed Code</span>
            <span onClick={() => handleShow("url")}><BsShare /> Share</span>
            <span onClick={(e) => handleDuplicate(e)} ><HiOutlineDocumentDuplicate /> Duplicate</span>
            <Link to={`/view-business?bid=${curElem.id}&design=true`}> <SiMaterialdesignicons /> Page Design</Link>
            <ModalPopup
                curElem={curElem}
                show={show}
                handleClose={handleClose}
                embed={embed}
                setEmbed={setEmbed}
                type={type}
                compoType={compoType}
            />
        </>
    )
}

export default BusinessDropdown