export const palette = [
    "#5D07FE",
    "#5EE661",
    "#5A70CC",
    "#D22F3B",
    "#6D6D5C",
    "#7A5EBF",
    "#B108F3",
    "#A4DBE3",
    "#CB5D60",
    "#A996C2",
    "#277FCF",
    "#FC6DEB"
]