import React, { useState } from 'react'
import { BsCodeSlash, BsShare } from 'react-icons/bs'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi'
import { SiMaterialdesignicons } from 'react-icons/si'
import { useDispatch } from 'react-redux'
import ModalPopup from './ModalPopup'
import { onDuplicateProduct } from "../../Actions/ProductAction";
import { Link } from "react-router-dom";


const ProductDropdownData = ({ curElem, compoType, fetchProduct }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [type, setType] = useState("")
    const [embed, setEmbed] = useState({
        header: '',
        headerStatus: false,
        checkout: '',
        checkoutStatus: false,
    })

    const handleShow = (val) => {
        setType(val)
        setEmbed({
            ...embed,
            header: `<script type="text/Javascript" id="pe_widget" src="https://backend.fastpaid.io/front-end/pro-widget.js?pid=${curElem.id}"></script>`,
            checkout: `<div id="fastpaid_checkout_section"></div>`
        })
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }
    const handleDuplicate = (curElem) => {
        dispatch(onDuplicateProduct(curElem, fetchProduct))
    }
    return (
        <>
            <span onClick={() => handleShow("copy")}><BsCodeSlash /> Embed Code</span>
            <span onClick={() => handleShow("url")}><BsShare /> Share</span>
            <span onClick={(e) => handleDuplicate(curElem)} ><HiOutlineDocumentDuplicate /> Duplicate</span>
            <Link to={`/create-product?bid=${curElem.bid}&pid=${curElem.id}&design=true`}> <SiMaterialdesignicons /> Page Design</Link>
            <ModalPopup
                curElem={curElem}
                show={show}
                handleClose={handleClose}
                embed={embed}
                setEmbed={setEmbed}
                type={type}
                compoType={compoType}
            />
        </>
    )
}

export default ProductDropdownData