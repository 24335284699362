import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
// import { setAlert } from '../../actions/alert';
// import { setStore, updateStore } from '../../actions/baseActions';
import { baseURL } from '../../Global/Global';
// import Alert from '../Alert';

const BaseData = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    // const storeData = useSelector(state => state.base.storeData)
    const [updateLoader, setUpdateLoader] = useState(false);
    const [baseCurrencyData, setBaseCurrencyData] = useState([])
    const [data, setData] = useState({
        status: false,
        id: "",
        storeName: '',
        baseCurrency: '',
        symbol: ''
    })
    console.log(auth, "auth")

    useEffect(() => {
        if (auth) {
            setData({
                ...data,
                // id: storeData.id,
                // storeName: storeData.storeName,
                baseCurrency: auth.user.baseCurrency,
                symbol: auth.user.symbol,
                status: true
            })
        }
    }, [])

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     if (name === "baseCurrency") {
    //         const temp = baseCurrencyData.find(({ code }) => code === value)
    //         setData({
    //             ...data,
    //             baseCurrency: temp.code,
    //             symbol: temp.symbol
    //         })
    //     }
    //     else {
    //         setData({
    //             ...data,
    //             [name]: value
    //         })
    //     }
    // }

    // const handleSet = (e) => {
    //     e.preventDefault()
    //     setUpdateLoader(true)
    //     if (data.status) {
    //         let val = {
    //             id: data.id,
    //             storeName: data.storeName,
    //             baseCurrency: data.baseCurrency,
    //             symbol: data.symbol
    //         }
    //         dispatch(updateStore(auth.token, val, setUpdateLoader))
    //     }
    //     else {
    //         let val = {
    //             storeName: data.storeName,
    //             baseCurrency: data.baseCurrency,
    //             symbol: data.symbol
    //         }
    //          dispatch(setStore(auth.token, val, setUpdateLoader))
    //     }
    // }

    // useEffect(() => {
    //     axios({
    //         method: 'POST',
    //         url: `${baseURL}base-currency`,
    //         data: {},
    //         headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
    //     }).then((res) => {
    //         if (res.data.status === true) {
    //             let val = res.data.data
    //             let val1 = [...new Map(val.map(item => [item["symbol"], item])).values()];
    //             setBaseCurrencyData(val1)
    //         }
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }, []);



    return (
        <>
            <div className="profileBlock">
                <h2 className="text-center">Base Currency</h2>
                <form >
                    {/* <div className="form-group form-group-alt">
                        <label className="form-text text-muted">Store Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Store Name"
                            name="storeName"
                            value={data.storeName}
                            onChange={(e) => handleChange(e)}
                            required
                        />
                    </div> */}
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted">Base Currancy</label>
                        {data.status ?
                            <input
                                type="text"
                                className="form-control"
                                name="baseCurrency"
                                placeholder="Base Currency"
                                value={data.baseCurrency}
                                required
                                style={{ backgroundColor: "#c9c6c6" }}
                                readOnly={true}
                            /> :
                            <select
                                type="text"
                                className="form-control"
                                name="baseCurrency"
                                value={data.baseCurrency}
                                // onChange={(e) => handleChange(e)}
                                required
                            >
                                <option value={""}>Select Currency</option>
                                {baseCurrencyData.length > 0
                                    ? baseCurrencyData.map((curElem, index) => {
                                        return (
                                            <option
                                                value={curElem.id}
                                            >{`${curElem.code} - ${curElem.currency}`}</option>
                                        );
                                    })
                                    : ""}
                            </select>}
                    </div>
                    <div className="inp-wrap" style={{ marginTop: "20px" }}>
                        <div class="alert alert-info text-center" role="alert">
                            Please note that: Base currency can be set only one time. Once you set it, you can't update it
                        </div>
                    </div>
                    {/* <button type="submit" className="fbtn btn-block demoLink">Update {updateLoader ? <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button> */}
                </form>
            </div>
        </>

    );
}

export default BaseData;
