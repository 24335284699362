import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const getCoupon =
  (data, setCouponData, setLoader) => (dispatch, getState) => {
    commonAxios("list-coupon", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status === true) {
          setCouponData(res.data);
        } else {
          setCouponData([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

export const saveCoupon =
  (data, setLoader, navigate,bid) => (dispatch, getState) => {
    commonAxios("create-coupon", data, dispatch, getState().auth.token)
      .then((res) => {
        if ((res.status = true)) {
          dispatch(setAlert(res.msg, "success"));
          navigate(`/coupon?bid=${bid}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const deleteCoupon =
  (data, setLoader, fetchCouponData) => (dispatch, getState) => {
    commonAxios("delete-coupon", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status === true) {
          dispatch(setAlert(res.msg, "success"));
          fetchCouponData();
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const updateCoupon =
  (data, setLoader,loader, navigate,bid) => (dispatch, getState) => {
    commonAxios("update-coupon", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status === true) {
          dispatch(setAlert(res.msg, "success"));
          setLoader({
            ...loader,
            loader:false
          });
          navigate(`/coupon?bid=${bid}`);
        } else {
          dispatch(setAlert(res.msg, "danger"));
          setLoader({
            ...loader,
            loader:false
          });
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          loader:false
        });
      });
  };

export const viewCoupon =
  (data, setCoupon,loader,setLoader) => (dispatch, getState) => {
    commonAxios("view-coupon", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setCoupon(res.data[0]);
          setLoader({
            ...loader,
            mainLoader:false,
          })
        } else {
          dispatch(setAlert(res.msg, "danger"));
          setLoader({
            ...loader,
            mainLoader:false,
          })
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          mainLoader:false,
        })
      });
  };

export const applyCoupon = (data, setLoader,setApply, setProductId) => (dispatch, getState) => {
  commonAxios("assign-coupon", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        setApply(false)
        setLoader(false);
        setProductId("")
      } else {
        dispatch(setAlert(res.msg, "danger"));
        setLoader(false);
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
      setLoader(false);
    });
};

export const fetchProductsData=(data,setProduct)=>(dispatch,getState)=>{
  commonAxios("fetch-list-product",data,dispatch,getState().auth.token)
  .then((res)=>{
    if(res.status){
      setProduct(res.data)
      console.log(res.data)

    }
  })
  .catch((err)=>{
    dispatch(setAlert(err.msg,"danger"))
})
}