import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const onUploadBusinessLogo =
  (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: "ADD_BUSINESS_LOGO", payload: res.data.path });
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          loadLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          loadLoader: false,
        });
      });
  };

export const onCreateBusiness =
  (ep, data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios(ep, { businessUrl: data.url, description: data.description, name: data.name }, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          navigate(`/view-business?bid=${res.data}`);
          setLoader(false);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onSubmitBusiness =
  (loader, setLoader) => (dispatch, getState) => {
    let state = getState().business.data;

    let obj;
    const val = { ...state };
    delete val.created;
    delete val.modified;
    delete val.status;
    delete val.userId;
    delete val.isDeleted;

    obj = val;
    obj = {
      ...obj,
      openingHour: JSON.stringify(obj.openingHour),
    };
    commonAxios('update-business', obj, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          saveLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          saveLoader: false,
        });
      });
  };

export const onDeleteBusiness = (data, fetchdata) => (dispatch, getState) => {
  commonAxios("delete-business", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        fetchdata();
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onFetchBusinessList =
  (setState, setLoader, active) => (dispatch, getState) => {
    commonAxios("fetch-list-business", {}, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          if (active) {
            let objArr = [];
            res.data.forEach((curElem) => {
              let temp = {
                label: curElem.name,
                value: curElem.id,
              };
              objArr.push(temp);
            });
            setState(objArr);
          } else {
            let arr = [...res.data];
            if (!setLoader) {
              arr.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                } else {
                  return -1;
                }
              });
            }
            setState(arr);
          }
        } else {
          setState([]);
        }
        if (setLoader) {
          setLoader(false);
        }
      })
      .catch((err) => {
        if (setLoader) {
          setLoader(false);
        }
        dispatch(setAlert(err.msg, "danger"));
      });
  };
export const onDuplicateBusiness =
  (data, handleDuplicate) => (dispatch, getState) => {
    commonAxios("duplicate-business", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          handleDuplicate();
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const onGetBusiness =
  (data, navigate, setLoader, loader) => (dispatch, getState) => {
    commonAxios("view-business", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          let initialState = getState().business.data
          let obj = { ...res.data[0] };

          obj = {
            ...obj,
            openingHour:
              res.data[0].openingHour === ""
                ? initialState.openingHour
                : JSON.parse(res.data[0].openingHour),
            mode:
              res.data[0].mode === ""
                ? initialState.mode
                : res.data[0].mode,
            design:
              res.data[0].design === "" ? initialState.design : res.data[0].design,
            timeFormat: res.data[0].timeFormat === "" ? initialState.timeFormat : res.data[0].timeFormat,
            socialMedia:
              res.data[0].socialMedia === null
                ? initialState.socialMedia
                : res.data[0].socialMedia,
          };

          dispatch({ type: "SET_BUSINESS", payload: obj });
          setLoader({
            ...loader,
            mainLoader: false,
          });
        } else {
          dispatch(setAlert(res.msg, "danger"));
          navigate("/business");
          setLoader({
            ...loader,
            mainLoader: false,
          });
        }
      })
      .catch((err) => {
        if (setLoader) {
          setLoader({
            ...loader,
            mainLoader: false,
          });
        }
      });
  };

export const onCheckBusinessUrl =
  (name, data, state, setState, type, setLoader, navigate) => (dispatch, getState) => {
    commonAxios(
      "check-business-url",
      { url: data.url, type: name },
      dispatch,
      getState().auth.token
    )
      .then((res) => {
        if (res.status === true) {
          if (name === "domain") {
            if (setLoader) {
              setLoader(false)
            }
            setState({ ...state, domainCheck: res.status, domainMsg: res.msg, domainBtnType: "Add this domain" });
          } else {
            setState({
              ...state,
              checkBusinessUrl: res.status,
              checkBusinessMessage: res.msg,
            });
            if (type === "submit") {
              dispatch(onCreateBusiness("create-business", data, setLoader, navigate));
            } else {
              setLoader(false)
            }
          }
        } else {
          if (setLoader) {
            setLoader(false)
          }
          if (name === "domain") {
            setState({ ...state, domainCheck: res.status, domainMsg: res.msg, domainBtnType: "Check" });
          } else {
            setState({
              ...state,
              checkBusinessUrl: res.status,
              checkBusinessMessage: res.msg,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err)
        if (setLoader) {
          setLoader(false)
        }
      });
  };

export const onSubmitBusinessUrl = (id, url) => (dispatch, getState) => {
  let data = {
    bid: id,
    storeName: url
  };
  commonAxios("add-subdomain", data, dispatch, getState().auth.token)
    .then((res) => { })
    .catch((err) => {
      dispatch(setAlert(err.message, "danger"));
    });
};

export const onSubmitDomain = (id, url, state, setState, loader, setLoader) => (dispatch, getState) => {
  let data = {
    bid: id,
    domainName: url
  };
  commonAxios("add-addon-domain", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        setState({ ...state, domainBtnType: "Check" });
        setLoader(false)
      } else {
        setState({ ...state, domainBtnType: "Check" });
        dispatch(setAlert(res.msg, "danger"));
        setLoader(false)
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.message, "danger"));
      setState({ ...state, domainBtnType: "Check" });
      setLoader(false)
    });
};

export const fetchUserImage = (setState) => (dispatch, getState) => {
  commonAxios("fetch-images", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setState(res.data)
      } else {
        setState([])
      }
    }).catch((err) => {
      dispatch(setAlert(err.message, "danger"))
    })
}

export const addDesignData = (data) => (dispatch, getState) => {

  dispatch({ type: "ADD_DESIGN_DATA", payload: data });
  let state = getState().business.data;
  let obj;
  const val = { ...state };
  delete val.created;
  delete val.modified;
  delete val.status;
  delete val.userId;
  delete val.isDeleted;

  obj = val;

  obj = {
    ...obj,
    openingHour: JSON.stringify(obj.openingHour),
  };
  commonAxios("update-business", obj, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
      } else {
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.message, "danger"));
    });
};

export const fetchPaymentIntegration = (data) => (dispatch, getState) => {
  commonAxios("autoresponder-list", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "ADD_PAYMENT_CONNECTION", payload: res.data })
      }
    }).catch((err) => {
      dispatch(setAlert(err.message, "danger"))
    })
}

export const onConnectPayment = (ep, state, setLoader, handleClose) => (dispatch, getState) => {
  commonAxios(ep, state, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        let obj = {
          bid: state.bid
        }
        dispatch(fetchPaymentIntegration(obj));
        handleClose();
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false);
    })
    .catch((err) => {
      dispatch(setAlert(err.message, "danger"));
    });
};

export const onDeleteIntegration = (data, bid) => (dispatch, getState) => {
  commonAxios("delete-connection", data, dispatch, getState().auth.token).then(
    (res) => {
      if (res.status) {
        let obj = {
          bid: bid
        }
        dispatch(fetchPaymentIntegration(obj));
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    }
  );
};

export const onConnectAutoRes =
  (data, checkStatus, setCheckStatus, randomString, setLoader) =>
    (dispatch, getState) => {
      commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
        .then((res) => {
          if (res.status) {
            let myWindow = window.open(
              res.data.outhUrl,
              "_blank",
              "width=700, height=500"
            );
            let obj = {
              bid: data.bid
            }
            let interval = setInterval(() => {
              if (checkStatus) {
                dispatch(checkResponse(randomString, interval, myWindow, setCheckStatus, obj)
                );
              }
            }, 5000);
          } else {
            dispatch(setAlert(res.msg, "danger"));
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    };

export const onPostTwilio = (data, setState, setLoader, handleClose2) => (dispatch, getState) => {
  commonAxios("add-twillio", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"))
        let obj = {
          bid: data.bid
        }
        dispatch(fetchPaymentIntegration(obj));
        setState([])

      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader(false)
      handleClose2()
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "success"))
      setLoader(false)
    })
}

export const onConnectPopAuto =
  (data, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("autoresponder-auth-url", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          let obj = {
            bid: data.bid
          }
          dispatch(fetchPaymentIntegration(obj));
          dispatch(setAlert(res.msg, "success"));
          handleClose();
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

export const checkResponse =
  (string1, interval, myWindow, setCheckStatus, obj) =>
    (dispatch, getState) => {
      let data = {
        key: string1,
      };
      commonAxios("check-network-status", data, dispatch, getState().auth.token)
        .then((res) => {
          if (res.status) {
            dispatch(fetchPaymentIntegration(obj))
            myWindow.close()
            setCheckStatus(false)
            dispatch(setAlert(res.msg, "success"));
            clearInterval(interval);
          } else {
            setCheckStatus(false)
          }
        })
        .catch((err) => {
          window.close();
          console.log(err)
        })
    }

export const onUploadDesignBackground = (name, data, loader, setLoader, imageFetchData) => (dispatch, getState) => {
  commonAxios("upload-image", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        imageFetchData()
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader({ ...loader, [name]: false })
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
      setLoader({ ...loader, [name]: false })
    });
};

export const onDeleteUploadImage = (id, imageFetchData) => (dispatch, getState) => {
  commonAxios("delete-user-image", { id: id }, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        imageFetchData()
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
    }).catch((err) => {
      dispatch(setAlert(err.message, "danger"))
    })
}

export const onFetchAnalytics = (data, setAnalytics, setLoader) => (dispatch, getState) => {
  commonAxios("fetch-analytics", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setAnalytics(res.data)
        setLoader(false)
        // dispatch(setAlert(res.msg, "success"))
      } else {
        setLoader(false)
        dispatch(setAlert(res.msg, "danger"))
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg))
      setLoader(false)
    })
}


// No Axios Data
export const addBusinessData = (name, value) => (dispatch) => {
  const data = { name, value };
  dispatch({ type: "ADD_BUSINESS_DATA", payload: data });
};


export const addFormateHours = (checked, state) => (dispatch) => {
  let arr = [];
  if (checked) {
    state.openingHour.map((curElem) => {
      let to = parseInt(curElem.toTime.split(":")[0]);
      arr.push({ ...curElem, toTime: `${to + 12}:00`, fromMeridiem: "", toMeridiem: "" })
    })
  } else {
    state.openingHour.map((curElem) => {
      let to = parseInt(curElem.toTime.split(":")[0]);
      arr.push({ ...curElem, toTime: `${to - 12}:00`, fromMeridiem: "AM", toMeridiem: "PM" })
    })

  }
  dispatch({ type: "ADD_FORMATE_DATA", payload: arr });
};

export const addTimeFormate = (val) => (dispatch) => {
  dispatch({ type: "ADD_TIME_FORMATE", payload: val });
};

export const addLocalDefault = (checked) => (dispatch) => {
  dispatch({ type: "ADD_LOCAL_DEFAULT", payload: checked });
};

export const addDataBusinessAll = (data) => (dispatch) => {
  dispatch({ type: "ADD_BUSINESS_ALL", payload: data });
};

export const onEnableDay = (index, checked) => (dispatch) => {
  let data = { index, checked };
  dispatch({ type: "ON_ENABLE_DAY", payload: data });
};

export const onAddMeridiemFrom = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_FROM_MERIDIEM", payload: data });
};

export const onAddMeridiemTo = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_TO_MERIDIEM", payload: data });
};

export const onAddTimeFrom = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_FROM_TIME", payload: data });
};

export const onAddTimeTo = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_TO_TIME", payload: data });
};

export const onUnmountCompo = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_BUSINESS" });
};
