import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import iconWrite from "../../images/icon-write.svg";
import iconRight from "../../images/icon-arrow.svg";
import modalCloseIcon from "../../images/modal-close.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../Actions/AlertAction";
import { appName } from "../../Global/Global";
import ContentModal from "../CreateBusiness/ContentModal";
import { onGetButtonData } from "../../Actions/RebrandAction";
import { currencyData, addCurrency } from "../../Actions/AuthAction";

const DashboardHead = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const rebrandData = useSelector((state) => state.rebrand.data);

  const [loader, setLoader] = useState(false);
  const [currencyLoader, setCurrencyLoader] = useState(false);
  const [buttonData, setButtonData] = useState({
    totalCustomer: 0,
    totalRevenue: 0,
  });
  const [baseCurrency, setBaseCurrency] = useState([]);
  const [currency, setCurrency] = useState("");
  const [symbol, setSymbol] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);
  const handleshow3 = () => setShow3(true);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  const fetchButtonData = () => {
    setLoader(true);
    dispatch(onGetButtonData(setButtonData, setLoader));
  };

  const fetchCurrency = () => {
    let data = {};
    dispatch(currencyData(data, setBaseCurrency));
  };

  useEffect(() => {
    fetchButtonData();
    fetchCurrency();
  }, []);

  const handleCurrency = (e) => {
    const { value } = e.target;
    baseCurrency.find((elem) => {
      if (elem.id === value) {
        setCurrency(elem.currency);
        setSymbol(elem.code);
      }
    });
  };


  useEffect(() => {
    if (auth.user.baseCurrency === "") {
      handleshow3();
    }
  }, [auth]);

  const handleSet = (e) => {
    e.preventDefault();
    setCurrencyLoader(true);
    let data = {
      baseCurrency: currency,
      symbol: symbol,
    };
    dispatch(addCurrency(data, handleClose3, setCurrencyLoader));
  };

  return (
    <>
      <section className="hero-banner">
        {/* <div className="hero-video">
          <video style={{ width: "100%" }} autoPlay muted loop>
            <source src="https://reeelapps-app.s3.us-west-2.amazonaws.com/fastpaid+bg+video.mp4" />
          </video>
        </div> */}
        <div className="hero-banner-txt">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="hero-banner-left">
                  <h2>WELCOME TO</h2>
                  <h3 className="text-uppercase">
                    {rebrandData ? rebrandData.name : appName}
                  </h3>
                  <p>
                    Fast, secure, and hassle-free payments are now a reality.
                    Get an instant overview of incoming and outgoing
                    transactions, add funds to accounts quickly, process refunds
                    just as fast - all with the click of a button.
                  </p>
                  {/* <a onClick={handleShow} className="demoLink">
                    <i className="fa fa-play" aria-hidden="true"></i> Watch Demo
                    Video
                  </a> */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-right">
                  {auth.user.is_client_account !== "1" ? (
                    <span onClick={handleShow2} className="bannerLink">
                      <div className="bannerLink-left">
                        <span>
                          <img alt="" src={iconWrite} />
                        </span>{" "}
                      </div>
                      <div className="bannerLink-right">
                        <span className="pr-3">create invoice</span>
                        <img alt="" src={iconRight} />
                      </div>
                    </span>
                  ) : (
                    ""
                  )}
                  <Link to="/projects" className="bannerLink notClick">
                    <div className="bannerLink-left">
                      <span>
                        {loader ? (
                          <>
                            {" "}
                            <i className="fa fa-spinner fa-spin mr-2" />
                          </>
                        ) : (
                          buttonData.totalCustomer
                        )}
                      </span>{" "}
                    </div>
                    <div className="bannerLink-right">
                      <span className="pr-3">total clients</span>
                      <img alt="" src={iconRight} />
                    </div>
                  </Link>
                  {auth.user.is_client_account !== "1" ? (
                    <Link to="/integration" className="bannerLink notClick">
                      <div className="bannerLink-left">
                        <span>
                          {loader ? (
                            <>
                              <i className="fa fa-spinner fa-spin mr-2" />
                            </>
                          ) : (
                            <> ${buttonData.totalRevenue} </>
                          )}
                        </span>{" "}
                      </div>
                      <div className="bannerLink-right">
                        <span className="pr-3">gross revenue</span>
                        <img alt="" src={iconRight} />
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="VideoModal dashboard-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="p-0">
          <div onClick={handleClose} className="vidClose">
            <img alt="" src={modalCloseIcon} />
          </div>
          <div className="modalVidWrap">
            <div className="modalVid">
              <iframe
                src="https://player.vimeo.com/video/746081609?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false;"
                allowFullScreen=""
                allow="autoplay,fullscreen,picture-in-picture"
                title="Player for FastPaid Intro"
                data-ready="true"
                tabIndex="-1"
              ></iframe>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ContentModal show={show2} handleClose={handleClose2} />

      <Modal className="VideoModal smModal" show={show3} centered>
        <Modal.Body>
          <form className="modalForm" onSubmit={handleSet}>
            <div className="inp-wrap">
              <label htmlFor="currency">Base Currency</label>
              <select
                name="currency"
                onChange={(e) => handleCurrency(e)}
                className="inpField-inp react-select-container my-select-box mb-4"
                required
              >
                <option>Select Currency</option>
                {baseCurrency.length > 0
                  ? baseCurrency.map((curElem, index) => {
                    return (
                      <option
                        value={curElem.id}
                      >{`${curElem.code} - ${curElem.currency}`}</option>
                    );
                  })
                  : ""}
              </select>
            </div>

            <div className="inp-wrap">
              <div class="alert alert-info text-center" role="alert">
                Please note that: Base currency can be set only one time. Once
                you set it, you can't update it
              </div>
            </div>

            <div className="inp-wrap">
              <button className="btn btn-primary inpBtn" type="submit">
                Submit{" "}
                {currencyLoader ? (
                  <i className="fa fa-spinner fa-spin mr-2" />
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardHead;
