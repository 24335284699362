import produce from "immer";
const initialState = {
  data: {
    bid: "",
    name: "",
    description: "",
    price: "1",
    currency: "",
    frequency: "",
    remaining: "",
    period: "",
    numberOfPayment: "1",
    tracking: "",
    isPhone: "",
    isFields: "",
    isAddress: "",
    legal: "",
    openingHours: [
      {
        day: "Sunday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Monday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Tuesday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Wednesday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Thursday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Friday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      },
      {
        day: "Saturday",
        enable: false,
        fromTime: "9:00",
        fromMeridiem: "AM",
        toTime: "9:00",
        toMeridiem: "PM"
      }
    ],
    isSameAsBusiness: "1",
    image: "",
    mode: "sandbox",
    logo: "",
    isExist: "",
    existData: {
      title: "",
      button: "",
      body: "",
      templateId: "",
    },
    productType: "",
    thankuPage: "",
    payment: "",
    autoresponder: "",
    slug: "",
    tag: "",
    staff: [],
    productStatus: true,
    paymentProcess: [],
    design: {
      imageEnabled: true,
      nameEnabled: true,
      descriptionEnable: true,
    },
  },
};

export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_P_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.value,
        },
      };

    case "SET_PRODUCT":
      return produce(state, (draft) => {
        draft.data = action.payload;
      });

    case "ADD_STAFF_DATA":
      return produce(state, (draft) => {
        draft.data.staff = action.payload;
      });

    case "ADD_CHECKBOX_OBJ":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.obj,
        },
      };

    case "ENABLE_DATA":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: action.payload.checked,
        },
      };
    case "ADD_PRODUCT_IMAGE":
      return produce(state, (draft) => {
        draft.data.logo = action.payload;
      });
    case "ADD_PRODUCT_DESIGN":
      return produce(state, (draft) => {
        draft.data.design = action.payload;
      });

    case "ON_ENABLE_PRODUCT_DAY":
      return produce(state, (draft) => {
        draft.data.openingHours[action.payload.index].enable =
          action.payload.checked;
      });

    case "ADD_PRODUCT_FROM_MERIDIEM":
      return produce(state, (draft) => {
        draft.data.openingHours[action.payload.index].fromMeridiem =
          action.payload.value;
      });
    case "ADD_PRODUCT_TO_MERIDIEM":
      return produce(state, (draft) => {
        draft.data.openingHours[action.payload.index].toMeridiem =
          action.payload.value;
      });

    case "ADD_PRODUCT_FROM_TIME":
      return produce(state, (draft) => {
        draft.data.openingHours[action.payload.index].fromTime =
          action.payload.value;
      });

    case "ADD_PRODUCT_TO_TIME":
      return produce(state, (draft) => {
        draft.data.openingHours[action.payload.index].toTime =
          action.payload.value;
      });

    case "UNMOUNT_PRODUCT":
      return initialState;

    default:
      return state;
  }
};
