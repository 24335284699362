import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {BsCheckSquare} from "react-icons/bs";

import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi";
import { GrCheckboxSelected } from "react-icons/gr";
import {
  getCoupon,
  deleteCoupon,
  applyCoupon,
  fetchProductsData,
} from "../../Actions/CouponActioin";
import queryString from "query-string";
import Modal from "react-bootstrap/Modal";
import modalCloseIcon from "../../images/modal-close.png";
import moment from "moment";

const Coupons = () => {
  const location = useLocation();
  const bid = queryString.parse(location.search).bid;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState([]);
  const [product, setProduct] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("");
  const [pageNumber, setPageNumber] = useState([]);
  const [apply, setApply] = useState(false);
  const [productId, setProductId] = useState("");
  const [cid, setCid] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [state, setState] = useState({
    name: false,
    startDate: false,
    startTo: false,
    type: false,
    amount: false,
    mode: false,
  });

  const fetchCouponData = () => {
    let data = {};
    dispatch(getCoupon(data, setCouponData, setLoader));
  };

  const fetchProducts = () => {
    let data = { bid: bid };
    dispatch(fetchProductsData(data, setProduct));
  };

  useEffect(() => {
    setLoader(true);
    fetchProducts();
  }, []);

  const handlsort = (type) => {
    let data = [...coupon];
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.couponCode.toLowerCase() < b.couponCode.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.couponCode.toLowerCase() > b.couponCode.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    } else if (type === "startDate") {
      if (state.startDate) {
        data.sort((a, b) => {
          if (a.couponStart.toLowerCase() < b.couponStart.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          startDate: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.couponStart.toLowerCase() > b.couponStart.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          startDate: true,
        });
      }
    } else if (type === "startTo") {
      if (state.startTo) {
        data.sort((a, b) => {
          if (a.couponTo.toLowerCase() < b.couponTo.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          startTo: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.couponTo.toLowerCase() > b.couponTo.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          startTo: true,
        });
      }
    } else if (type === "type") {
      if (state.type) {
        data.sort((a, b) => {
          if (a.couponType.toLowerCase() < b.couponType.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          type: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.couponType.toLowerCase() > b.couponType.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          type: true,
        });
      }
    } else if (type === "amount") {
      if (state.amount) {
        data.sort((a, b) => {
          if (+a.couponAmount < +b.couponAmount) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          amount: false,
        });
      } else {
        data.sort((a, b) => {
          if (+a.couponAmount > +b.couponAmount) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          amount: true,
        });
      }
    }
    setCoupon(data);
    // console.log(coupon);
  };

  useEffect(() => {
    setLoader(true);
    fetchCouponData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/create-coupon?bid=${bid}&id=${id}`);
  };
  const handleDelete = (id) => {
    let data = { id: id };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        dispatch(deleteCoupon(data, setLoader, fetchCouponData));
      }
    });
  };

  const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
  const currentTodo =
    (search === "" && mode !== "") ||
    (search !== "" && mode === "") ||
    (search !== "" && mode !== "")
      ? coupon
      : coupon.slice(indexofFirstTodo, indexofLastTodo);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };
  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };

  useEffect(() => {
    if (coupon.length > 0) {
      let maxLength = coupon.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [coupon]);

  const handleSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "1") {
      setMode("1");
    } else if (value === "0") {
      setMode("0");
    }
  };

  useEffect(() => {
    if (search === "" && mode === "") {
      setCoupon(couponData);
    } else {
      const val = couponData
        .filter((curElem) => {
          if (mode === "") {
            return curElem;
          } else {
            let data = curElem.status === mode;
            return data;
          }
        })
        .filter((curElem) => {
          let data = curElem.couponCode
            .toLowerCase()
            .includes(search.toLowerCase());
          if (data) {
            return curElem;
          }
        });

      setCoupon(val);
    }
  }, [mode, search, couponData]);

  const handleApply = (id) => {
    setApply(true);
    setCid(id);
  };

  const handleClose = () => {
    setApply(false);
    setProductId("");
  };

  const onSubmit = () => {
    let data = {
      pid: productId,
      bid: bid,
      cid: cid,
    };
    setLoader(true);
    dispatch(applyCoupon(data, setLoader, setApply, setProductId));
  };

  return (
    <>
      <TitleBar title="Coupon" />
      <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <ViewBusinessNav />

            <div className="business-top">
              <h2>Coupons</h2>
              <Link to={`/create-coupon?bid=${bid}`}>
                <button className="demoLink mt-0">
                  Create Coupon <FiArrowRight />{" "}
                </button>
              </Link>
            </div>
            <div className="business-list">
              <div className="business-search">
                <div className="business-search-inp">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    className="inpSearch withIcon"
                    type="text"
                    onChange={handleSearch}
                    placeholder="Search coupon name"
                  />
                </div>
                <div className="business-search-inp ml-2">
                  <select
                    name=""
                    id=""
                    className="inpSearch"
                    onChange={(e) => handleSelect(e)}
                  >
                    <option value={""}>All</option>
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <thead>
                    <tr>
                      {/* <th width="150"></th> */}
                      <>
                        <th
                          onClick={() => handlsort("name")}
                          className="cursor-pointer ml-2"
                        >
                          Name
                        </th>
                        <th
                          onClick={() => handlsort("startDate")}
                          className="cursor-pointer"
                        >
                          Start From
                        </th>
                        <th
                          onClick={() => handlsort("startTo")}
                          className="cursor-pointer"
                        >
                          Ends On
                        </th>
                        <th
                          onClick={() => handlsort("type")}
                          className="cursor-pointer"
                        >
                          Type
                        </th>
                        <th
                          onClick={() => handlsort("amount")}
                          className="cursor-pointer"
                        >
                          Amount
                        </th>
                        <th
                          onClick={() => handlsort("status")}
                          className="cursor-pointer"
                        >
                          Status
                        </th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </>
                    </tr>
                  </thead>

                  <tbody>
                    {currentTodo.length > 0
                      ? currentTodo.map((curElem) => {
                          return (
                            <tr key={curElem.id}>
                              <td>{curElem.couponCode}</td>
                              <td>{curElem.couponStart}</td>
                              <td>{curElem.couponTo}</td>
                              <td>{curElem.couponType}</td>
                              <td>{curElem.couponAmount}</td>
                              <td>
                                {" "}
                                {curElem.status === "0"  ? (
                                  <span className="pMode sandbox">
                                    Inactive
                                  </span>
                                ) : (
                                  <span className="pMode live">Active</span>
                                )}
                              </td>

                              <td>
                                <a className="roundBox">
                                  <FiEdit
                                    className="smIcon cursor-pointer mr-2"
                                    onClick={() => handleEdit(curElem.id)}
                                    title="Edit"
                                  />
                                </a>
                                <a className="roundBox">
                                  <FiTrash2
                                    className="smIcon cursor-pointer ml-1"
                                    onClick={() => handleDelete(curElem.id)}
                                    title="Delete"
                                  />
                                </a>
                                <a className="roundBox">
                                  <BsCheckSquare
                                    className="smIcon cursor-pointer ml-1"
                                    onClick={() => handleApply(curElem.id)}
                                    title="Apply"
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
                {loader === true ? (
                  ""
                ) : couponData.length === 0 ? (
                  ""
                ) : currentTodo.length !== 0 ? (
                  ""
                ) : (
                  <div className="text-center mt-4 text-capitalize" >
                    "No Data Found"
                  </div>
                )}

                {couponData.length === 0 ? (
                  loader ? (
                    <div
                      className="text-center"
                      style={{ fontSize: "xx-large" }}
                    >
                      <i className="fa fa-spinner fa-spin mx-4" />
                    </div>
                  ) : (
                    <div className="text-center mt-4 ">
                      <p style={{ color: "#2552af" }}>
                        "You have not created any coupons yet"
                      </p>
                    </div>
                  )
                ) : (
                  ""
                )}

                <div className="tablepage">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft style={{ color: pageNumber.length<=1?"gray":"white" }} />
                      </a>
                    </li>

                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                          return (
                            <li
                              key={index}
                              id={curElem}
                              style={{ color: "white" }}
                              onClick={() => handleClick(curElem)}
                              className="cursor-pointer"
                            >
                              <a
                                style={
                                  curElem === pagination.currentPage
                                    ? {
                                        backgroundColor: "white",
                                        color: "#400C46",
                                      }
                                    : {}
                                }
                              >
                                {curElem}
                              </a>
                            </li>
                          );
                        })
                      : ""}
                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length<=1?"not-allowed":"cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight style={{color: pageNumber.length<=1?"gray":"white" }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        className="VideoModal small"
        show={apply}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <div onClick={handleClose} className="vidClose">
            <img src={modalCloseIcon} alt="" />
          </div>
          <div className="formSec">
            <div className="inpField">
              <div className="row">
                <div className="col-lg-12">
                  <label className="mt-3 coupon_popUp" htmlFor="">
                    Apply Coupon
                  </label>
                  <div className="inpLabel inplabel_2 searchInp ">
                    <select
                      name="pid"
                      id=""
                      onChange={(e) => setProductId(e.target.value)}
                      className="inpLabel-inp inplabel_text font-1"
                    >
                      <option value="">Select Your Product</option>
                      {product.length > 0
                        ? product.map((curElem, index) => {
                            return (
                              <option value={curElem.id}>{curElem.name}</option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="btn-block inpBtn mt-3 create-modal-btn"
              onClick={onSubmit}
              disabled={productId === "" ? true : false}
              style={{
                background: productId !== "" ? "" : "gray",
              }}
            >
              {loader ? (
                <>
                  Applying
                  <i
                    className="fa fa-spinner fa-spin mx-2"
                    style={{ fontSize: "15px" }}
                  />
                </>
              ) : (
                "Apply"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Coupons;
