import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onFetchAutoresponder,
  addStaffData,
  getStaffData,
  onGetProduct,
  addCheckboxObj,
  addProductData,
  addProductEnable,
  onUpdateProduct,
  onUploadProductImage,
  onSameAsBusiness
} from "../../Actions/ProductAction";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import OpeningHours from "../Product/OpeningHours";
import Swal from "sweetalert2";
import ProductPayment from "./ProductPayment";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ExitIntent from "./ExitIntent";
import queryString from "query-string";

const ProductGeneral = ({ pid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state.product.data);
  const businessState = useSelector((state) => state.business.data);
  const bid = queryString.parse(location.search).bid;
  const [loader, setLoader] = useState({
    loadLoader: false,
    saveLoader: false,
    mainLoader: false,
  });
  const [tags, setTags] = useState([]);
  const [staffOption, setStaffOption] = useState([]);

  const [trackingData, setTrackingData] = useState({
    isTracking: false,
    tracking: "",
  });

  const [legalData, setLegalData] = useState({
    isLegal: false,
    legal: "",
  });
  const [autoResponder, setAutoResponder] = useState([]);
  const [payment, setPayment] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "tracking") {
      setTrackingData({ ...trackingData, [name]: value });
      let obj = JSON.stringify({ ...trackingData, [name]: value });
      dispatch(addCheckboxObj(name, obj));
    }
    else if (name === "price") {
      if (value === "0" || value < 0) {
        dispatch(addProductData(name, 1));
      } else {
        dispatch(addProductData(name, value));
      }
    }
    else if (name === "numberOfPayment") {
      if (value === "0" || value < 0) {
        dispatch(addProductData(name, 1));
      } else {
        dispatch(addProductData(name, value));
      }
    }
    else if (name === "legal") {
      setLegalData({ ...legalData, [name]: value });
      let obj = JSON.stringify({ ...legalData, [name]: value });
      dispatch(addCheckboxObj(name, obj));
    }
    else {
      dispatch(addProductData(name, value));
    }
  };

  const autoResponderSelect = (e) => {
    const { name, value } = e.target;
    dispatch(addProductData(name, value));
  };

  const uploadImage = (e) => {
    let imageData = e.target.files[0];
    let allowedType = ["image/png", "image/jpg", "image/jpeg"];
    if (imageData) {
      if (allowedType.includes(imageData.type)) {
        if (imageData.size < 5000001) {
          const formData = new FormData();
          formData.append("upload_type", "logo");
          formData.append("file", imageData);
          setLoader({
            ...state,
            loadLoader: true,
          });
          dispatch(onUploadProductImage(formData, loader, setLoader));
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Max allowed size for image is 5MB.",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select a valid file type.",
        });
      }
    }
  };

  const addTags = (event) => {
    const { name, value } = event.target;
    if (event.key === "Enter" && value !== "") {
      setTags([...tags, event.target.value]);
      dispatch(addProductData(name, JSON.stringify([...tags, value])));
      event.target.value = "";
    }
  };


  const removeTags = (id) => {
    let array = tags.filter((_, index) => index !== id);
    setTags(array);
    dispatch(addProductData("tag", JSON.stringify(array)));
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    if (name === "mode") {
      let val = checked ? "live" : "sandbox";
      dispatch(addProductEnable(name, val));
    } else if (name === "isExist" && !checked) {
      dispatch(addProductData("existData", {}));
      dispatch(addProductEnable(name, checked));
    } else if (name === "isSameAsBusiness") {
      dispatch(onSameAsBusiness(name, checked, businessState));
    } else {
      dispatch(addProductEnable(name, checked));
    }
  };
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    if (name === "isLegal" && !checked) {
      setLegalData({ ...legalData, legal: "", [name]: checked });
      let obj = JSON.stringify({ ...legalData, legal: "", [name]: checked });
      dispatch(addCheckboxObj("legal", obj));
    }
    else if (name === "isTracking" && !checked) {
      setTrackingData({ ...trackingData, tracking: "", [name]: checked });
      let obj = JSON.stringify({ ...trackingData, tracking: "", [name]: checked, });
      dispatch(addCheckboxObj("tracking", obj));
    }
    else if (name === "isLegal") {
      setLegalData({ ...legalData, [name]: checked });
      let obj = JSON.stringify({ ...legalData, [name]: checked });
      dispatch(addCheckboxObj("legal", obj));
    } else {
      setTrackingData({ ...trackingData, [name]: checked });
      let obj = JSON.stringify({ ...trackingData, [name]: checked });
      dispatch(addCheckboxObj("tracking", obj));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader({
      ...loader,
      saveLoader: true,
    });
    dispatch(onUpdateProduct(state, loader, setLoader));
  };

  const fetchBusiness = () => {
    setLoader({
      ...loader,
      mainLoader: true,
    });
    let data = {
      id: pid,
    };
    dispatch(onGetProduct(data, loader, setLoader, navigate));
    dispatch(getStaffData(setStaffOption, loader, setLoader));
  };
  const onSelectStaff = (e) => {
    dispatch(addStaffData(e));
  };

  useEffect(() => {
    if (pid) {
      fetchBusiness();
    }
  }, [pid]);

  useEffect(() => {
    if (state.tracking) {
      let data = JSON.parse(state.tracking);
      setTrackingData({
        isTracking: data.isTracking,
        tracking: data.tracking,
      });
    }
    if (state.legal) {
      let data = JSON.parse(state.legal);
      setLegalData({
        isLegal: data.isLegal,
        legal: data.legal,
      });
    }
    if (state.tag) {
      let data = JSON.parse(state.tag);
      setTags(data);
    }
  }, [state]);

  useEffect(() => {
    dispatch(
      onFetchAutoresponder(bid, setAutoResponder, setPayment)
    );
  }, []);

  const preventPressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <>
      {loader.mainLoader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="productTabs-main">
            <div className="inpLabelWrap mt-0">
              <span className="labelTxt">Give your product a name.</span>
              <div className="inpLabel mt-0">
                <label htmlFor="name">
                  Name:
                  <span
                    style={{
                      float: "right",
                      fontweight: "normal",
                      marginRight: "10px",
                    }}
                  >
                    {state.name.length} / 100
                  </span>
                </label>
                <input
                  className="inpLabel-inp"
                  placeholder="Your Name Here"
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={(e) => handleChange(e)}
                  maxLength={100}
                  required
                />
              </div>
            </div>

            <div className="inpLabelWrap">
              <span className="labelTxt">Give your product Description.</span>
              <div className="inpLabel">
                <label htmlFor="description">Description:</label>
                <textarea
                  type="text"
                  className="inpLabel-inp"
                  placeholder="Add A Description"
                  name="description"
                  value={state.description}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </div>

            <div className="openHr">
              <div className="openHr-left">
                <label htmlFor="">
                  <strong>Payment Settings:</strong>
                </label>
              </div>
              <div className="openHr-right">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="inpLabelWrap mt-0">
                      <span className="labelTxt">Payment Frequency</span>
                      <div className="inpLabel">
                        <label htmlFor="frequency">Payment Frequency</label>
                        <select
                          className="inpLabel-inp square"
                          name="frequency"
                          value={state.frequency}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="fixed">{"Fixed Payment"}</option>
                          <option value="asyouwant">{"Pay As You Want"}</option>
                          <option value="recurring">{"Recurring"}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="inpLabelWrap mt-0">
                      <span className="labelTxt">
                        {state.frequency === "asyouwant"
                          ? "Give a minimum price."
                          : "Give your product price."}
                      </span>
                      <div className="inpLabel">
                        {state.frequency === "asyouwant" ? (
                          <label htmlFor="price">Minimum Price</label>
                        ) : (
                          <label htmlFor="price">Price</label>
                        )}
                        <input
                          className="inpLabel-inp"
                          placeholder="10"
                          type="number"
                          value={state.price}
                          name="price"
                          onKeyPress={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {state.frequency === "recurring" ? (
                    <>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt">Recurring Frequency</span>
                          <div className="inpLabel">
                            <label htmlFor="remaining">
                              Recurring Frequency
                            </label>
                            <select
                              className="inpLabel-inp square"
                              name="period"
                              value={state.period}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="fortnightly">Fort Nightly</option>
                              <option value="monthly">Monthly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="inpLabelWrap">
                          <span className="labelTxt"># of payments</span>
                          <div className="inpLabel">
                            <label htmlFor="numberOfPayment"># of payments</label>
                            <input
                              className="inpLabel-inp"
                              placeholder="10"
                              type="number"
                              name="numberOfPayment"
                              value={state.numberOfPayment}
                              onKeyPress={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="inpLabel d-flex justify-content-between line">
              <label htmlFor="">Checkout Fields:</label>
              <div className="d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isFields"
                    checked={+state.isFields}
                    onChange={(e) => handleChecked(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            {state.isFields ? (
              <div className="inpLabelWrap">
                <div className="row">
                  <div className="col-md-6">
                    <div className="locateBox mt-0 d-flex justify-content-between">
                      <div className="pr-3">Collect Phone</div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="isPhone"
                          checked={+state.isPhone}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="locateBox mt-0 ml-3d-flex justify-content-between">
                      <div className="pr-3">Collect Address</div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="isAddress"
                          checked={+state.isAddress}
                          onChange={(e) => handleChecked(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="inpLabel d-flex justify-content-between line">
              <label htmlFor="">Tracking:</label>
              <div className="d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isTracking"
                    checked={trackingData.isTracking}
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            {trackingData.isTracking ? (
              <div className="inpLabelWrap">
                <div className="inpLabel">
                  <label htmlFor="">Tracking</label>
                  <textarea
                    className="inpLabel-inp"
                    name="tracking"
                    placeholder="Tracking"
                    value={trackingData.tracking}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="inpLabel d-flex justify-content-between line">
              <label htmlFor="">Legal Url</label>
              <div className="d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isLegal"
                    checked={legalData.isLegal}
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {legalData.isLegal ? (
              <div className="inpLabel">
                <input
                  className="inpLabel-inp"
                  type="text"
                  name="legal"
                  placeholder="Enter your legal url"
                  value={legalData.legal}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            ) : (
              ""
            )}
            <div className="inpLabel d-flex justify-content-between line">
              <label htmlFor="">Exit intent Popup</label>
              <div className="d-flex">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="isExist"
                    checked={+state.isExist}
                    onChange={(e) => handleChecked(e)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div>
              {+state.isExist ? <ExitIntent /> : ""}

              <div className="inpLabelWrap">
                <span className="labelTxt">Product Type</span>
                <div className="inpLabel">
                  <label htmlFor="">Product Type</label>
                  <select
                    name="productType"
                    className="inpLabel-inp"
                    value={state.productType}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Type</option>
                    <option value="services">Services</option>
                    <option value="physical">Physical</option>
                    <option value="digital">Digital</option>
                  </select>
                </div>
              </div>

              {state.productType !== "digital" ? (
                <>
                  <div className="openHr">
                    <div className="openHr-left">
                      <div className="inpLabel">
                        <strong>Opening hours:</strong>
                      </div>
                    </div>

                    <div className="inpLabel d-flex justify-content-between line mt-3 mb-3">
                      <label htmlFor="">Same as business</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="isSameAsBusiness"
                            checked={+state.isSameAsBusiness}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    {+state.isSameAsBusiness ? (
                      ""
                    ) : (
                      <>
                        <div className="openHr-right">
                          {state.openingHours === ""
                            ? ""
                            : state.openingHours.map((curElem, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <OpeningHours
                                    curElem={curElem}
                                    index={index}
                                    format={businessState.timeFormat}
                                  />
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabelWrap">
                    <span className="labelTxt">Thankyou Page URL</span>
                    <div className="inpLabel">
                      <label htmlFor="">Thankyou Page URL</label>
                      <input
                        type="text"
                        className="inpLabel-inp"
                        name="thankuPage"
                        placeholder="Thankyou Page URL"
                        value={state.thankuPage}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
                {payment.length > 0 ? <ProductPayment payment={payment} /> : ""}

                <div className="col-lg-12">
                  <div className="inpLabelWrap">
                    <span className="labelTxt">AutoResponder</span>
                    <div className="inpLabel">
                      <label htmlFor="">AutoResponder</label>
                      <select
                        name="autoresponder"
                        id=""
                        className="inpLabel-inp"
                        value={state.autoresponder}
                        onChange={(e) => autoResponderSelect(e)}
                      >
                        <option value="">Select Option</option>
                        {autoResponder
                          ? autoResponder.map((ele) => {
                            let name;
                            if (ele.name === "gr") {
                              name = "getresponse";
                            } else if (ele.name === "mc") {
                              name = "mailchip";
                            } else if (ele.name === "sg") {
                              name = "sandgrid";
                            }
                            else if (ele.name === "si") {
                              name = "Sendiio";
                            }
                            else if (ele.name === "twillio") {
                              name = "Twillio";
                            }
                            else {
                              name = "Mailvio";
                            }
                            return (
                              <option
                                value={ele.id}
                              >{`${name}-${ele.data.user_name}`}</option>
                            );
                          })
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="inpLabelWrap">
                    <span className="labelTxt">URL Short</span>
                    <div className="inpLabel">
                      <label htmlFor="">URL Short</label>
                      <span className="inpLabel-inp"></span>
                      <div className="labelField">
                        <span className="">
                          https://{state.url}
                        </span>
                        {/* <input
                          type="text"
                          name="slug"
                          readOnly
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="inpLabel"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-6">
                        <label className="mb-2" htmlFor="">
                          Upload Product Image
                        </label>
                        <div className="style-upload-box">
                          <input
                            className="custom-file-input"
                            type="file"
                            onChange={(e) => uploadImage(e)}
                          />
                          <div>
                            <AiOutlineCloudUpload />
                            <h6>"Upload product image"</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 d-flex justify-content-center align-items-center">
                        <div className="business_image_wrapper">
                          {loader.loadLoader ? (
                            <div className="loader_img">
                              <i
                                className="fa fa-spinner fa-spin mr-2"
                                style={{ fontSize: "30px" }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <img src={state.logo} alt="Product image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="inpLabelWrap">
                    <span className="labelTxt">Tag</span>
                    <div className="tags-input " style={{ width: "100%" }}>
                      <ul id="tags">
                        {tags.map((tag, index) => (
                          <li key={index} className="tag">
                            <span className="tag-title">{tag}</span>
                            <span
                              className="tag-close-icon"
                              onClick={() => removeTags(index)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                      <input
                        style={{ paddingLeft: "15px" }}
                        placeholder="Tag"
                        type="text"
                        name="tag"
                        onKeyUp={(event) =>
                          event.key === "Enter" ? addTags(event) : null
                        }
                        onKeyPress={preventPressEnter}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="inpLabelWrap">
                    <span className="labelTxt">Staff</span>
                    <div className="inpLabel">
                      <Select
                        className="inpLabel-inp multiselect"
                        closeMenuOnSelect={true}
                        value={state.staff}
                        isMulti
                        options={staffOption}
                        onChange={(e) => onSelectStaff(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="inpLabel d-flex locateBox justify-content-between">
                <label htmlFor="">Product Status</label>
                <div className="d-flex">
                  <label className="switch flex">
                    <input
                      type="checkbox"
                      name="productStatus"
                      checked={+state.productStatus}
                      onChange={(e) => handleChecked(e)}
                    />
                    <span className="slider round"></span>
                    <span className="slClose">Deactivated</span>
                    <span className="slOpen">Active</span>
                  </label>
                </div>
              </div>

              <div className="inpLabel d-flex locateBox justify-content-between">
                <label htmlFor="">Mode</label>
                <div className="d-flex">
                  <label className="switch flex">
                    <input
                      type="checkbox"
                      name="mode"
                      checked={state.mode === "live" ? true : false}
                      onChange={(e) => handleChecked(e)}
                    />
                    <span className="slider round"></span>
                    <span className="slClose">Sandbox</span>
                    <span className="slOpen">Live</span>
                  </label>
                </div>
              </div>

              <div className="btn-sec text-right">
                <button type="submit" className="demoLink">
                  {loader.saveLoader ? (
                    <>
                      Updating{" "}
                      <i
                        className="fa fa-spinner fa-spin mx-1"
                        style={{ fontSize: "15px" }}
                      />
                    </>
                  ) : (
                    "Update"
                  )}{" "}
                </button>
              </div>
            </div>
          </div>
        </form >
      )}
    </>
  );
};

export default ProductGeneral;
