import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";
export const onUploadProductImage =
  (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch({ type: "ADD_PRODUCT_IMAGE", payload: res.data.path });
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          loadLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          loadLoader: false,
        });
      });
  };

export const fetchUserImage = () => (dispatch, getState) => {
  commonAxios("fetch-images", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
      } else {
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.message, "danger"));
    });
};

export const onSubmitProduct =
  (data, setLoader, navigate) => (dispatch, getState) => {
    let businessUrl = getState().business.data.url
    let obj = { ...data }
    obj = {
      ...obj,
      url: `${businessUrl}/product`
    }
    commonAxios("create-product", obj, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          navigate(`/create-product?bid=${data.bid}&pid=${res.data}`);
          setLoader(false);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onUpdateProduct =
  (state, loader, setLoader) => (dispatch, getState) => {
    let obj;
    const val = { ...state };
    delete val.isDeleted;
    delete val.userId;
    delete val.created;
    delete val.modified;
    delete val.currency;
    delete val.remaining;

    obj = val;
    obj = {
      ...obj,
      openingHours: JSON.stringify(obj.openingHours),
    };
    commonAxios("update-product", obj, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          saveLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          saveLoader: false,
        });
      });
  };
export const onUpdateProductDesign = (data) => (dispatch, getState) => {
  dispatch({ type: "ADD_PRODUCT_DESIGN", payload: data });
  let state = getState().product.data;

  let obj;
  const val = { ...state };
  delete val.isDeleted;
  delete val.userId;
  delete val.created;
  delete val.modified;
  delete val.currency;
  delete val.remaining;

  obj = val;
  if (state.isSameAsBusiness) {
    obj = {
      ...obj,
      openingHours: JSON.stringify(getState().business.data.openingHour),
    };
  } else {
    obj = {
      ...obj,
      openingHours: JSON.stringify(obj.openingHours),
    };
  }
  commonAxios("update-product", obj, dispatch, getState().auth.token)
    .then((res) => { })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onFetchProducts =
  (data, setState, setLoader, active) => (dispatch, getState) => {
    commonAxios("fetch-list-product", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          if (active) {
            let objArr = [];
            res.data.forEach((curElem) => {
              let temp = {
                label: curElem.name,
                value: curElem.id,
              };
              objArr.push(temp);
            });

            setState(objArr);
          } else {
            setState(res.data);
          }
        } else {
          setState([]);
        }
        if (setLoader) {
          setLoader(false);
        }
      })
      .catch((err) => {
        if (setLoader) {
          setLoader(false);
        }
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const onDeleteProduct = (curElem, fetchData) => (dispatch, getState) => {
  commonAxios(
    "delete-product",
    { id: curElem.id },
    dispatch,
    getState().auth.token
  )
    .then((res) => {
      if (res.status) {
        fetchData(curElem.bid);
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};

export const onGetProduct =
  (data, loader, setLoader, navigate, bid) => (dispatch, getState) => {
    commonAxios("view-product", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          let initialState = getState().product.data;
          let openingHour = getState().business.data.openingHour;
          let obj = { ...res.data[0] };

          obj = {
            ...obj,
            openingHours:
              res.data[0].openingHours === "" || +res.data[0].isSameAsBusiness
                ? openingHour
                : JSON.parse(res.data[0].openingHours),
            paymentProcess:
              res.data[0].paymentProcess === ""
                ? initialState.paymentProcess
                : JSON.parse(res.data[0].paymentProcess),
            design:
              res.data[0].design === null
                ? initialState.design
                : res.data[0].design,
            staff:
              res.data[0].staff === ""
                ? initialState.staff
                : JSON.parse(res.data[0].staff),
            price:
              res.data[0].price === "0"
                ? initialState.price
                : JSON.parse(res.data[0].price),
            numberOfPayment:
              res.data[0].numberOfPayment === ""
                ? initialState.numberOfPayment
                : JSON.parse(res.data[0].numberOfPayment),
            existData:
              res.data[0].existData === ""
                ? initialState.existData
                : JSON.parse(res.data[0].existData),
            mode:
              res.data[0].mode === ""
                ? initialState.mode
                : res.data[0].mode,
          };
          dispatch({ type: "SET_PRODUCT", payload: obj });
          setLoader({ ...loader, mainLoader: false });
        } else {
          navigate(`/product?bid=${bid}`);
          dispatch(setAlert(res.msg, "danger"));
          setLoader({ ...loader, mainLoader: false });
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({ ...loader, mainLoader: false });
      });
  };

export const getStaffData =
  (setStaffOption, loader, setLoader) => (dispatch, getState) => {
    commonAxios("list-staff", {}, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          let arr = [];
          if (res.data.length > 0) {
            res.data.map((curElem) => {
              arr.push({ label: curElem.name, value: curElem.id });
            });
          }
          setStaffOption(arr);
          setLoader({
            ...loader,
            mainLoader: false,
          });
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          mainLoader: false,
        });
      });
  };

export const onFetchAutoresponder =
  (id, setAutoResponder, setPayment) => (dispatch, getState) => {
    commonAxios(
      "autoresponder-integration-list",
      { bid: id },
      dispatch,
      getState().auth.token
    )
      .then((res) => {
        if (res.status) {
          setAutoResponder(res.data.autoresponder);
          setPayment(res.data.payment);
        }
      })
      .catch((err) => { });
  };

export const onDuplicateProduct =
  (curElem, fetchProduct) => (dispatch, getState) => {
    commonAxios(
      "duplicate-product",
      { id: curElem.id },
      dispatch,
      getState().auth.token
    )
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          fetchProduct(curElem.bid);
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
      });
  };

//No Axios Data

export const onSameAsBusiness =
  (name, checked, businessState) => (dispatch, getState) => {
    dispatch(addProductEnable(name, checked));
    if (checked) {
      dispatch(addProductData("openingHours", businessState.openingHour));
    } else {
      let arr = []
      businessState.openingHour.forEach((ele) => {
        if (businessState.timeFormat === "24") {
          arr.push({ ...ele, enable: false, fromTime: "9:00", fromMeridiem: "", toTime: "21:00", toMeridiem: "" })
        } else {
          arr.push({ ...ele, enable: false, fromTime: "9:00", fromMeridiem: "AM", toTime: "9:00", toMeridiem: "PM" })
        }
      })
      dispatch(addProductData("openingHours", arr));
    }
  };

export const addStaffData = (data) => (dispatch, getState) => {
  dispatch({ type: "ADD_STAFF_DATA", payload: data });
};

export const addCheckboxObj = (name, obj) => (dispatch) => {
  dispatch({ type: "ADD_CHECKBOX_OBJ", payload: { name, obj } });
};

export const addProductData = (name, value) => (dispatch) => {
  let data = { name, value };
  dispatch({ type: "ADD_P_DATA", payload: data });
};

export const addProductEnable = (name, checked) => (dispatch) => {
  let data = { name, checked };
  dispatch({ type: "ENABLE_DATA", payload: data });
};

export const onEnableProductDay = (index, checked) => (dispatch) => {
  let data = { index, checked };
  dispatch({ type: "ON_ENABLE_PRODUCT_DAY", payload: data });
};

export const onProductAddMeridiemFrom = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_PRODUCT_FROM_MERIDIEM", payload: data });
};

export const onProductAddMeridiemTo = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_PRODUCT_TO_MERIDIEM", payload: data });
};

export const onProductAddTimeFrom = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_PRODUCT_FROM_TIME", payload: data });
};

export const onProductAddTimeTo = (index, value) => (dispatch) => {
  let data = { index, value };
  dispatch({ type: "ADD_PRODUCT_TO_TIME", payload: data });
};

export const onUnmountComProduct = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_PRODUCT" });
};
