import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const onSubmitClient =
  (ep, state, loader, setLoader, navigate) => (dispatch, getState) => {
    let data = { ...state };
    data = {
      ...data,
      openingHour: JSON.stringify(data.openingHour),
    };
    commonAxios(ep, data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          dispatch(setAlert(res.msg, "success"));
          navigate("/business-customer");
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader({
          ...loader,
          saveLoader: false,
        });
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader({
          ...loader,
          saveLoader: false,
        });
      });
  };

export const fetchBusinessCustomer =
  (data, setCustomer, setLoader, remainder) => (dispatch, getState) => {
    commonAxios("customer-list", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setCustomer(res.data);
        } else {
          setCustomer([]);
           if(remainder){
            dispatch(setAlert(res.msg, "danger"))
           }
        }
        if (setLoader) {
          setLoader(false);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        if (setLoader) {
          setLoader(false);
        }
      });
  };
export const fetchProductsCustomer =
  (data, setProduct) => (dispatch, getState) => {
    commonAxios("view-product", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setProduct(res.data);
        }
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
      });
  };

export const onUploadCSV =
  (data, fetchCustomer, onClose, setLoader) => (dispatch, getState) => {
    commonAxios("upload-csv", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          fetchCustomer();
          dispatch(setAlert(res.msg, "success"));
          onClose();
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        dispatch(setAlert(err.msg, "danger"));
        setLoader(false);
      });
  };

export const onFetchustomerData = (data, setLoader, loader) => (dispatch, getState) => {
  commonAxios("view-customer", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "SET_CUSTOMER_DATA", payload: res.data[0] });
        dispatch(setAlert(res.msg, "success"));
        setLoader({
          ...loader,
          mainLoader: false,
        })
      } else {
        dispatch(setAlert(res.msg, "danger"));
        setLoader({
          ...loader,
          mainLoader: false,
        })
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
      setLoader({
        ...loader,
        mainLoader: false,
      })
    });
};

export const onDeleteCustomer = (id, fetchCust) => (dispatch, getState) => {
  let data = {
    id: id
  }
  commonAxios("delete-customer", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"))
        fetchCust()
      }
      else {
        dispatch(setAlert(res.msg, "danger"))
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const sendRefund = (data) => (dispatch, getState) => {
  commonAxios("", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
    })
    .catch((err) => {
      dispatch(setAlert(err.msg, "danger"));
    });
};


// No Axios Data

export const onUnmountCustomer = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_CLIENT" });
};


export const addClientData = (name, value) => (dispatch) => {
  const data = { name, value };
  dispatch({ type: "ADD_CLIENT_DATA", payload: data });
};

export const onUnmountCompo = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_BUSINESS" });
};
