import React from "react";
import { FaMoneyBill } from "react-icons/fa";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { onDeleteCustomer, sendRefund } from "../../Actions/ClientAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const ClientRows = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEdit = (id) => {
    navigate(`/create-clients?id=${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this customer!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this customer!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        dispatch(onDeleteCustomer(id, props.fetchCust))
      }
    });
  }

  const handleRefund = (id) => {
    let data = { id: id }
    Swal.fire({
      title: "Refund?",
      text: "Are you sure!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sending...",
          timer: 1000,
        });
        dispatch(sendRefund(data));
      }
    });
  }

  let statusData = {
    classData: props.paymentStatus === "Non Paid" ? "sandbox" : "live",
    nameData: props.paymentStatus === "Non Paid" ? "Non Paid" : "Paid",
  };
  return (
    <>
      <tr>
        <td>{props.name}</td>
        <td>{props.bid ? props.productName : props.email}</td>
        <td>{props.bid ? props.price : props.phone}</td>
        <td>
          {props.bid ? (
            props.paidBy
          ) : (
           ""
          )}
        </td>
        <td>
          {props.bid ? (
            <span className={`pMode ${statusData.classData}`}>
              {statusData.nameData}
            </span>
          ) : (
           ""
          )}
        </td>
        <td>
          {props.bid ? (
            <a className="" onClick={() => handleRefund(props.id)}>
              <FaMoneyBill style={{ fontSize: "25px",cursor:"pointer"}}
                title="Refund"
              />
            </a>
          ) : (
            <>
            <a>
              <FiEdit
                className="smIcon cursor-pointer mr-2"
                onClick={() => handleEdit(props.id)}
                title="Edit"
              />
            </a>
            <a>
              <FiTrash2
                className="smIcon cursor-pointer mr-2"
                onClick={() => handleDelete(props.id)}
                title="Delete"
              />
            </a>
          </>
          )}
        </td>
      </tr>
    </>
  );
};

export default ClientRows;
