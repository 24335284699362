import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import dummyBusiness from '../../images/logoKoin.png'
// import proimg from "../../images/img4.png";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Actions/AlertAction";
import axios from "axios";
import { baseURL } from "../../Global/Global";
import swal from "sweetalert";
import { AiOutlineConsoleSql } from "react-icons/ai";
// import Alert from "../Alert";
import BaseData from "./BaseData";


const BusinessSetup = () => {
    // const auth = useSelector(state => state.auth)
    // const dispatch = useDispatch()
    // let allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];
    // const [loader, setLoader] = useState({
    //     submit: false,
    //     image: false
    // })
    // const [businessId, setBusinessId] = useState(-1)
    // const [data, setData] = useState({
    //     name: "",
    //     email: "",
    //     phone: "",
    //     image: "",
    //     linkUrl: ""
    // })


    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     setData({
    //         ...data,
    //         [name]: value
    //     })
    // }

    // const onInputFile = (e) => {
    //     if (e.target.files[0] !== undefined) {
    //         if (allowedExt.includes(e.target.files[0].type)) {
    //             let allowedSize = 5000000;
    //             if (e.target.files[0].size < allowedSize) {
    //                 setLoader({
    //                     ...loader,
    //                     image: true
    //                 })
    //                 const formData = new FormData();
    //                 formData.append('type', 'businessImage');
    //                 formData.append('file', e.target.files[0])
    //                 axios({
    //                     method: "POST",
    //                     url: `${baseURL}upload-user-media`,
    //                     data: formData,
    //                     headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
    //                 }).then((res) => {
    //                     if (res.data.status === true) {
    //                         setData({
    //                             ...data,
    //                             image: res.data.path
    //                         })
    //                     }
    //                     else {
    //                         dispatch(setAlert(res.data.message, "danger"))
    //                     }
    //                     setLoader({
    //                         ...loader,
    //                         image: false
    //                     })
    //                 }).catch((error) => {
    //                     setLoader({
    //                         ...loader,
    //                         image: false
    //                     })
    //                     console.log(error)
    //                 })
    //             }
    //             else {
    //                 swal("Oops!", "Max allowed size for image 5MB");
    //             }
    //         }
    //         else {
    //             swal("Oops!", "You have Selected Invalid File Type");
    //         }
    //     }
    // }

    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     let val = { ...data }
    //     val.businessId = businessId
    //     setLoader({
    //         ...loader,
    //         submit: true
    //     })
    //     axios({
    //         method: "POST",
    //         url: `${baseURL}business-info`,
    //         data: val,
    //         headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
    //     }).then((res) => {
    //         if (res.data.status === true) {
    //             dispatch(setAlert(res.data.message, "success"))
    //             fetchBusiness()
    //         }
    //         else {
    //             dispatch(setAlert(res.data.message, "danger"))
    //         }
    //         setLoader({
    //             ...loader,
    //             submit: false
    //         })
    //     }).catch((error) => {
    //         setLoader({
    //             ...loader,
    //             submit: false
    //         })
    //         console.log(error)
    //     })
    // }

    // const fetchBusiness = () => {
    //     axios({
    //         method: "POST",
    //         url: `${baseURL}business-info-get`,
    //         data: data,
    //         headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
    //     }).then((res) => {
    //         if (res.data.status === true) {
    //             let temp = res.data.data
    //             setData({
    //                 ...data,
    //                 name: temp.name,
    //                 email: temp.email,
    //                 phone: temp.phone,
    //                 image: temp.image,
    //                 linkUrl: temp.linkUrl
    //             })
    //             setBusinessId(temp.id)
    //         }
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    // useEffect(() => {
    //     fetchBusiness()
    // }, [])

    return (
        <>
            <TitleBar title="Business" />
            <Navbar />
            {/* <Alert /> */}

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="proImage-wrap">
                                    <div className="proImage">
                                        {/* <div className="proImage-holder image-deco">
                                            {loader.image ? <i className="fa fa-spinner fa-spin" />
                                                : <img src={data.image ? data.image : dummyBusiness} alt="" />}
                                        </div> */}
                                    </div>
                                </div>

                                {/* <div className="customFile text-center ">
                                    <button type="submit" className="fbtn demoLink ">Upload Your Business Logo</button>
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={(e) => onInputFile(e)}
                                        required
                                        className="cursor-pointer"
                                    ></input>
                                </div> */}

                                {/* <form className="profileBlock" onSubmit={handleSubmit}>
                                    <h2 className="text-center">Business Information</h2>
                                    <div >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group form-group-alt">
                                                    <label>Enter Your Business Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Business Name"
                                                        name="name"
                                                        value={data.name}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group form-group-alt">
                                                    <label>Enter Your Business Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Business Email"
                                                        name="email"
                                                        value={data.email}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group form-group-alt">
                                                    <label>Enter your Support Link</label>
                                                    <input
                                                        type="url"
                                                        className="form-control"
                                                        placeholder="Support Link"
                                                        name="linkUrl"
                                                        value={data.linkUrl}
                                                        required
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group form-group-alt">
                                                    <label>Enter Your Business Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Business Phone Number"
                                                        name="phone"
                                                        value={data.phone}
                                                        onChange={(e) => handleChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="fbtn btn-block demoLink">Update {loader.submit ?
                                            <i className="fa fa-spinner fa-spin mx-2" /> : ''}</button>
                                    </div>
                                </form> */}


                                <BaseData />


                            </div>
                        </div>

                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default BusinessSetup;