import queryString from 'query-string';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { onConnectPayment } from '../../Actions/BusinessAction';
import modalCloseIcon from '../../images/modal-close.png';
import { onPostTwilio } from '../../Actions/BusinessAction';


const TwilioPopuo = (props) => {

    let location = useLocation()
    let bId = queryString.parse(location.search).bid
    // const business = useSelector(state => state.business.data)
    const dispatch = useDispatch()

    const [state,setState] = useState({
        user_name:"",
        accountSid:"",
        authToken:"",
        fromNumber:"",
        toNumber:"",
        bid:bId
    })
    const [loader,setLoader] =  useState(false)

    const handleChange=(e)=>{
    const {name,value}= e.target
      setState({
        ...state,
        [name]:value
      })
    }

    const handleSubmit=(e)=>{
          e.preventDefault()
          setLoader(true)
          let data = {...state}
          dispatch(onPostTwilio(data,setState,setLoader,props.handleClose2))
    }

  return (
    <> <Modal className="VideoModal small" show={props.show} onHide={props.handleClose2} centered>
    <Modal.Body>
    <div onClick={props.handleClose2} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
    <form className="formSec" onSubmit={handleSubmit}>
                    <h4 style={{ color: "#000000" }}>{props.name} SMS Integration </h4>
                    <hr style={{ color: "#000", opacity: 1 }} />
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="name">Enter Name</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Name"
                                        type="text"
                                        name='user_name'
                                        value={state.user_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="name">Enter Account SID</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Account SID"
                                        type="text"
                                        name='accountSid'
                                        value={state.accountSid}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="clientId">Enter Auth Token</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter Auth Token"
                                        type="text"
                                        name='authToken'
                                         value={state.authToken}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="secretId">Enter From Number</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter From Number"
                                        type="text"
                                        name='fromNumber'
                                         value={state.fromNumber}
                                         onChange={handleChange}
                                         required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="secretId">Enter To Number</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Enter To Number (Need for Testing)"
                                        type="text"
                                        name='toNumber'
                                        value={state.toNumber}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">{loader ? <>Connecting {props.name}<i className="fa fa-spinner fa-spin mx-1" /> </> : `Connect ${props.name}`}</button>
                </form>
       
    </Modal.Body >
</Modal ></>
  )
}

export default TwilioPopuo