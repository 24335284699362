import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setAlert } from "../../Actions/AlertAction";
import { baseURL } from "../../Global/Global";

const AddAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })
    // const [resellerData, setResellerData] = useState([]);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team',
        email: '',
        name: '',
        password: '',
    });

    const onInputChange = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeamData(res.data.data.team_members)
                setClientData(res.data.data.clients)
                // setResellerData(res.data.data.resellers)
            }
        }).catch(error => {

        })
    }

    const addClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}create-client`,
            data: userDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam()
                setUserDetails({
                    ...userDetails,
                    type: 'team_member',
                    email: '',
                    name: '',
                    password: ''
                })
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (userDetails.password) {
            if (pattern.test(userDetails.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                setLoader(true);
                addClientTeam();
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            addClientTeam();
        }
    }

    useEffect(() => {
        fetchClientTeam();
    }, []);

    return (
        <>

            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">

                    <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select className="form-control" name="type" onChange={(e) => onInputChange(e)}>
                                        <option style={{ backgroundColor: "#000", color: "#fff" }} value="team">Team Member</option>
                                        <option style={{ backgroundColor: "#000", color: "#fff" }} value="client">Client Account</option>
                                        {/* <option style={{ backgroundColor: "#000" }} value="reseller">Reseller Account</option> */}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted ">Account Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        required
                                        name="name"
                                        value={userDetails.name}
                                        onChange={(e) => onInputChange(e)}
                                        onKeyDown={(e) => {
                                            if (e.key === ' ' || e.code === 32) {
                                                const inputValue = e.target.value;
                                                if (inputValue.length === 0 || inputValue[0] === ' ') {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={userDetails.email}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="********"
                                        required
                                        name="password"
                                        value={userDetails.password}
                                        onChange={(e) => onInputChange(e)}
                                        minLength={6}
                                        maxLength={32}
                                    />
                                    <p style={{ fontSize: 13, margin: "5px" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader}>
                                {loader ? <>Creating <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Create'}
                            </button>
                        </div>

                    </form>

                </div>


                <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} type="Client" />
                <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
                {/* <ManageTeam users={resellerData} fetchClientTeam={fetchClientTeam} type="Reseller" /> */}
            </div>

        </>
    )
}

export default AddAccount;