import React from 'react'
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi"
import Dropdown from 'react-bootstrap/Dropdown'

import dotImg from "../../images/dots.png";
import { onDeleteProduct } from '../../Actions/ProductAction'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import ProductDropdownData from './ProductDropdownData';

const ProductRow = ({ curElem, fetchProducts }) => {
    const dispatch = useDispatch()

    const deleteProduct = (curElem) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Deleting...',
                    timer: 1000
                })
                dispatch(onDeleteProduct(curElem, fetchProducts))
            }
        })
    }

    return (
        <tr>
            <td>
                <div className="proThumb">
                    <img src={curElem.logo} alt="" />
                </div>
            </td>
            <td><strong>{curElem.name}</strong> <br /> <span className="proType text-capitalize">{curElem.productType}</span></td>
            <td>{curElem.id}</td>
            <td>
                {curElem.mode === "live" ?
                    <span className="pMode live">Live</span> :
                    <span className="pMode sandbox">SandBox</span>
                }
            </td>
            <td>{curElem.price === "0" ? "1" : curElem.price}</td>
            <td>
                <a className="roundBox" href={`https://${curElem.url}`} target="_blank" title="Publish"><FiExternalLink /></a>
                <Link className="roundBox" style={{ color: "#002981" }} to={`/create-product?bid=${curElem.bid}&pid=${curElem.id}`} title="Edit"><FiEdit /></Link>
                <span className="roundBox" onClick={() => deleteProduct(curElem)} title="Delete" ><FiTrash2 /></span>
            </td>
            <td>
                <Dropdown className="tableDrop">
                    <Dropdown.Toggle variant="n">
                        <img src={dotImg} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ProductDropdownData
                            curElem={curElem}
                            compoType="product"
                            fetchProduct={fetchProducts}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    )
}

export default ProductRow