import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import {
  addClientData,
  onSubmitClient,
  onFetchustomerData,
  fetchProductsCustomer,
  onUnmountCustomer,
} from "../../Actions/ClientAction";
import { FiArrowRight } from "react-icons/fi";
import { FaBuilding } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import ViewBusinessNav from "../Business/ViewBusinessNav";

const CreateClients = () => {
  const location = useLocation();
  const bid = queryString.parse(location.search).bid;
  const id = queryString.parse(location.search).id;

  const dispatch = useDispatch();
  const state = useSelector((state) => state.client.data);
  const navigate = useNavigate();

  const [loader, setLoader] = useState({
    loadLoader: false,
    saveLoader: false,
    mainLoader: false,
  });
  const [product, setProduct] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      let val = value;
      val = value.match("^[0-9.]*$");
      if (val) {
        dispatch(addClientData(name, value));
      } else {
      }
    } else {
      dispatch(addClientData(name, value));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoader({
      ...loader,
      saveLoader: true,
    });
    let ep;
    let obj;
    const val = { ...state, bid: "0", pid: "0", id };

    obj = val;
    if (obj.id) {
      ep = "update-customer";
    } else {
      ep = "create-customer";
    }
    dispatch(onSubmitClient(ep, obj, loader, setLoader, navigate));
  };

  const fetchProducts = () => {
    let data = { id: bid };
    dispatch(fetchProductsCustomer(data, setProduct));
  };

  const fetchCustomerData = () => {
    if (id) {
      setLoader({
        ...loader,
        mainLoader: true,
      });
      let data = { id: id };
      dispatch(onFetchustomerData(data, setLoader, loader));
    }
  };

  useEffect(() => {
    fetchCustomerData();
    return () => {
      dispatch(onUnmountCustomer());
    };
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      {loader.mainLoader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <TitleBar title="Create Clients" />
          {bid?<Navbar  activeNav={"business"}/>:<Navbar activeNav={"customer"}/>}
          <section className="siteWrap">
            <div className="container">
              <div className="business-wrap">
                {/* {bid ? <ViewBusinessNav active={"customer"} /> : ""} */}

                <div className="business-top">
                  {id ? <h2>Edit Customer</h2> : <h2>Create Customer</h2>}
                </div>
                <form onSubmit={onSubmit}>
                  <div className="business-list">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                          <span className="labelTxt">Name</span>

                          <div className="inpLabel">
                            <label htmlFor="name">
                              Name{" "}
                              <span
                                style={{
                                  float: "right",
                                  fontweight: "normal",
                                  marginRight: "10px",
                                }}
                              >
                                {" "}
                                {state.name.length} / 100
                              </span>
                            </label>
                            <input
                              className="inpLabel-inp"
                              name="name"
                              placeholder="Add Name"
                              value={state.name}
                              onChange={(e) => handleChange(e)}
                              type="text"
                              maxLength={100}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="inpLabelWrap mt-0">
                          <span className="labelTxt">Email</span>
                          <div className="inpLabel">
                            <label htmlFor="email">Email</label>
                            <input
                              className="inpLabel-inp"
                              name="email"
                              placeholder="Add Email"
                              value={state.email}
                              onChange={(e) => handleChange(e)}
                              type="email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="inpLabelWrap">
                      <span className="labelTxt">Phone</span>
                      <div className="inpLabel">
                        <label htmlFor="phone">Phone</label>
                        <input
                          className="inpLabel-inp"
                          name="phone"
                          placeholder="Add Phone"
                          value={state.phone}
                          onChange={(e) => handleChange(e)}
                          type="text"
                          maxLength={15}
                          required
                        />
                      </div>
                    </div>

                    <div className="row"></div>

                    <div className="btn-sec text-right mt-4">
                      {id ? (
                        <button type="submit" className="demoLink">
                          {loader.saveLoader ? (
                            <>
                              Updating Customer
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Update Customer"
                          )}
                        </button>
                      ) : (
                        <button type="submit" className="demoLink">
                          {loader.saveLoader ? (
                            <>
                              Creating Customer
                              <i
                                className="fa fa-spinner fa-spin mx-2"
                                style={{ fontSize: "15px" }}
                              />
                            </>
                          ) : (
                            "Create Customer"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default CreateClients;
