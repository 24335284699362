import { commonAxios } from "../Global/CommonAxios";
import { setAlert } from "./AlertAction";

export const onEditClient = (accountDetails, fetchClientTeam, setLoader, setToggle) => (dispatch, getState) => {
    commonAxios("edit-client-account", accountDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                dispatch(setAlert(res.msg, 'success'));
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setLoader(false);
            setToggle(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}


export const onDeleteAcc = (data, fetchClientTeam, setLoader, Swal) => (dispatch, getState) => {
    commonAxios("delete-client", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchClientTeam()
                Swal.close()
                dispatch(setAlert(res.msg, 'success'))
            } else {
                dispatch(setAlert(res.msg, 'danger'))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, 'danger'))
            setLoader(false);
        })

}
