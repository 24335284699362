import queryString from 'query-string';
import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onConnectPopAuto } from '../../Actions/BusinessAction';
import modalCloseIcon from '../../images/modal-close.png';

const AutoResponderPopup = (props) => {
    let location = useLocation()
    let bId = queryString.parse(location.search).bid
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        bid: bId,
        type: props.type,
        name: "",
        token: "",
        secret: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = { ...state }
        if (props.type === "mv") {
            delete data.secret
        }
        dispatch(onConnectPopAuto(data, setLoader, props.handleClose))
    }



    return (
        <Modal className="VideoModal small" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <h4 style={{ color: "#000000" }}>{props.name} Payment Integration </h4>
                    <hr style={{ color: "#000", opacity: 1 }} />
                    <div className="inpLabelWrap ">
                        <div className='row'>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-dark my-1' htmlFor="name">Enter Name</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Name"
                                        type="text"
                                        name='name'
                                        value={state.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='col-lg-12 my-1'>
                                <div className='inpLabel'>
                                    <label className='text-dark' htmlFor="token">Enter Token</label>
                                    <input
                                        className="inpLabel-inp"
                                        placeholder="Token"
                                        type="text"
                                        name='token'
                                        value={state.token}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            {props.type === "si" ?
                                <div className='col-lg-12 my-1'>
                                    <div className='inpLabel'>
                                        <label className='text-dark' htmlFor="secret">Enter Secret</label>
                                        <input
                                            className="inpLabel-inp"
                                            placeholder="Secret"
                                            type="text"
                                            name='secret'
                                            value={state.secret}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div> : ""}
                        </div>
                    </div>

                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">{loader ? <>Connecting {props.name}<i className="fa fa-spinner fa-spin mx-1" /> </> : `Connect ${props.name}`}</button>
                </form>
            </Modal.Body >
        </Modal >
    )
}

export default AutoResponderPopup